import { Suspense, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import "./global.css";
import "./mobileView.css";
import Footer from "./pages/footer/Footer";
import MobileFooter from "./pages/footer/MobileFooter";
import Header from "./pages/header/Header";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { WishlistContextProvider } from "./context/WishlistContext";
import { CartContextProvider } from "./context/CartContext";
import { AuthProvider } from "./context/AuthContext";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-blue/theme.css";
import {
  browserName,
  deviceType,
  osVersion,
  osName,
  isMobile,
} from "react-device-detect";
import DynamicBodyClass from "./DynamicBodyClass";
import { guestUserLogin } from "./api/lib/user";
import { Skeleton } from "primereact/skeleton";
import { ProgressSpinner } from "primereact/progressspinner";

function App() {
  const location = useLocation();
  const match = location.pathname.match(/^\/product\/(.+)/);
  const SKU = match ? match[1] : null;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      !localStorage.getItem("accessToken") &&
      !localStorage.getItem("guestToken")
    ) {
      createGuestLogin();
    } else {
      setIsLoading(false)
    }
  }, []);

  useEffect(() => {
    console.log(browserName, deviceType, osName, osVersion);
    if (browserName) {
      document.body.classList.add(browserName.replace(/ /g, ""));
    }
    if (deviceType) {
      document.body.classList.add(deviceType.replace(/ /g, ""));
    }
    if (osName) {
      document.body.classList.add(osName.replace(/ /g, ""));
    }
    if (osVersion) {
      document.body.classList.add(osVersion.replace(/ /g, ""));
    }

    // Cleanup function to remove classes when component unmounts
    return () => {
      document.body.classList.remove(
        browserName.replace(/ /g, ""),
        deviceType.replace(/ /g, ""),
        osName.replace(/ /g, ""),
        osVersion.replace(/ /g, "")
      );
    };
  }, []);

  const createGuestLogin = async () => {
    try {
      const response = await guestUserLogin();
      const accessToken = response.data.data.accessToken;
      localStorage.setItem("guestId", response.data.data.userId);
      localStorage.setItem("isGuest", response.data.data.isGuest);
      localStorage.setItem("guestToken", accessToken);
      return;
    } catch (error) {
      console.error("Error creating guest login", error);
      return;
    } finally {
      setIsLoading(false); // Stop loading once data is fetched
    }
  };

  if (isLoading) {
    // Show a loading screen or spinner
    return (
      <div className="flex inset-0 bg-white bg-opacity-60 flex items-center justify-center z-10 h-screen">
        <ProgressSpinner
          style={{ width: "40px", height: "40px" }}
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </div>
    );
  }

  return (
    <>
      <DynamicBodyClass />
      <PrimeReactProvider>
        <AuthProvider>
          <CartContextProvider>
            <WishlistContextProvider>
              {location.pathname !== "/login" &&
              (location.pathname !== "/help&support" || !isMobile) &&
              (location.pathname !== "/profile/orders" || !isMobile) &&
              (location.pathname !== "/account" || !isMobile) &&
              (location.pathname !== "/delivery" || !isMobile) &&
              (location.pathname !== "/contact-us" || !isMobile) &&
              (location.pathname !== "/faq" || !isMobile) &&
              (location.pathname !== "/cart" || !isMobile) ? (
                <Header />
              ) : (
                ""
              )}
              <Suspense
                fallback={
                  <div className="flex flex-col items-center mt-3">
                    <Skeleton width="90%" className="mb-2"></Skeleton>
                    <Skeleton
                      width="90%"
                      height="20rem"
                      className="mb-2"
                    ></Skeleton>
                    <Skeleton
                      width="90%"
                      height="2rem"
                      className="mb-2"
                    ></Skeleton>
                    <Skeleton
                      width="90%"
                      height="10rem"
                      className="mb-2"
                    ></Skeleton>
                    <Skeleton width="10rem" height="4rem"></Skeleton>
                  </div>
                }
              >
                <Outlet />
              </Suspense>
              {location.pathname !== "/login" &&
              (location.pathname !== "/help&support" || !isMobile) &&
              (location.pathname !== "/profile/orders" || !isMobile) &&
              (location.pathname !== "/products" || !isMobile) &&
              (location.pathname !== `/product/${SKU}` || !isMobile) &&
              (location.pathname !== "/account" || !isMobile) &&
              (location.pathname !== "/delivery" || !isMobile) &&
              (location.pathname !== "/contact-us" || !isMobile) &&
              (location.pathname !== "/faq" || !isMobile) &&
              (location.pathname !== "/cart" || !isMobile) ? (
                <Footer />
              ) : (
                ""
              )}
              {isMobile ? <MobileFooter /> : ""}
            </WishlistContextProvider>
          </CartContextProvider>
        </AuthProvider>
      </PrimeReactProvider>
    </>
  );
}

export default App;
