import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const DynamicBodyClass = () => {
  const location = useLocation();

  useEffect(() => {
    const productDetailsRegex = /^\/product\/.+/;
    
    const routeClassMap = {
      "/help&support": "help-support-container",
      "/contact-us": "contact-us-container",
      "/faq": "faq-container",
      "/products": "products-container",
      "/profile/orders": "orders-container",
      "/delivery": "delivery-container",
      "/account": "account-container",
      "/cart": "cart-container"
    };

    // Get the class for the current route
    let currentClass = null
    currentClass = routeClassMap[location.pathname];

    if (productDetailsRegex.test(location.pathname)) {
      currentClass = "product-container";
    }

    if (currentClass) {
      // Add the class to the body
      document.body.classList.add(currentClass);

      // Clean up: remove the class when the component unmounts or route changes
      return () => {
        document.body.classList.remove(currentClass);
      };
    }
  }, [location.pathname]); // Re-run effect whenever the pathname changes

  return null; // This component doesn't render anything itself
};

export default DynamicBodyClass;
