import { Box, Container, Grid } from "@mui/material";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { RazorpayOrderOptions, useRazorpay } from "react-razorpay";
import { useNavigate } from "react-router-dom";
import { checkout, getAllCart } from "../../api/lib/cart";
import { getUserAddressList } from "../../api/lib/user";
import CartCard from "../../components/cart-card/CartCard";
import LocationPopup from "../../components/pop-up-components/locationPopup";
import { useCart } from "../../context/CartContext";
import PaymentGateway from "../payment-gateway/PaymentGateway";

const CartPage = () => {
  const navigate = useNavigate();
  const { error, isLoading, Razorpay } = useRazorpay();

  const [productIDs, setProductIDs] = useState([]);
  const [checkoutCost, setCheckoutCost] = useState<any>({});
  const [address, setAddress] = useState([]);

  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const { cartItems, setCartItems } = useCart();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    fetchCartItems();
    getAddressList();
  }, []);

  const handleHide = () => {
    setVisible(false);
    getAddressList();
  };

  const showAddressPopup = () => {
    setVisible(true);
  };


  // Handle selection of a payment option
  const handleOptionAddress = (option) => {
    setSelectedAddress(option);
  };
  const getAddressList = async () => {
    try {
      const response = await getUserAddressList();
      if (response.data?.data[0]) {
        setSelectedAddress(response.data?.data[0]);
        setAddress(response.data?.data);
      }
    } catch (error) {
      console.error("Error fetching address list", error);
    }
  };

  const fetchCartItems = async () => {
    try {
      const response = await getAllCart();
      if (response.data.data) {
        console.log("response.data.data", response.data.data)
        localStorage.setItem("cartId", response.data.data._id);
        const totalCost = response.data.data.cartTotal;

        setCheckoutCost(totalCost);
        const cartDetails = response.data.data.items;
        setCartItems(response.data.data.items);

        const catalogIds = cartDetails?.map((item) => item.catalogId);
        setProductIDs(catalogIds);
      }
    } catch (error) {
      console.error("Error Fetching IDs", error);
    }
  };

  const razorpayCheckout = async (e) => {
    e.preventDefault(); // Prevent default action
    const response = await checkout({
      shippingDetailId: selectedAddress._id
    });
    console.log("respone", response);

    const options: RazorpayOrderOptions = {
      ...response.data.data.checkoutPayload,
      handler: (response) => {
        razorPayResponseHandler(response);
      },
    };

    const razorpayInstance = new Razorpay(options);
    razorpayInstance.open();
  };

  const razorPayResponseHandler = (response: any) => {
    console.log("razorPayResponseHandler", response);
    navigate(`/profile/transaction/${response.razorpay_order_id}`);
  };

  // Empty dependency array to run only once on mount

  // useEffect(() => {
  //   const fetchCartProducts = async () => {
  //     try {
  //       // const productPromises = productIDs.map(id => getRequiredProductFromCatalog({ sku: id }));
  //       // const productResults = await Promise.all(productPromises);
  //       // const products = productResults.map(result => result.data.data);
  //       const response = await getAllCart();
  //       console.log("response",response,response.data.data)
  //       setCartItems(response.data.data);
  //     } catch (error) {
  //       console.error("Error Loading Products", error);
  //     }
  //   };

  //   // if (productIDs.length > 0) {
  //     fetchCartProducts();
  //   // }
  // }, [productIDs]);

  return (
    <>
      <Container
        className="view-cart-container py-6 min-h-[60vh]"
        maxWidth="lg"
      >
        {/* <div className="text-xl font-bold mb-3 mt-3 ml-2 pb-1 border-solid border-b-[3px] border-primary inline-block">
          My Bag: <span className="text-lg font-light   ">{cartItems?.length} item(s)</span>
        </div> */}
        {cartItems?.length < 1 && (
          <Box className="mt-3 flex justify-center items-center flex-col">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="236"
              height="204"
              fill="none"
              viewBox="0 0 236 204"
              className="mb-8"
            >
              <path
                fill="#F5F5F5"
                d="m73.01 64.834 15.149 92.099h106.216l7.159-78.294z"
              ></path>
              <path
                fill="#9596A4"
                d="M178.368 93.894a5.605 5.605 0 0 1 5.083 6.153l-3.823 37.897a3.657 3.657 0 0 1-3.813 3.258 3.65 3.65 0 0 1-3.473-3.618l-.08-38.088a5.62 5.62 0 0 1 1.818-4.147 5.62 5.62 0 0 1 4.288-1.455"
              ></path>
              <path
                fill="url(#paint0_linear_291_3070)"
                d="m199.257 103.568-4.882 53.37h-28.012c-9.261-24.257 7.202-50.733 32.894-53.37"
                style={{ mixBlendMode: "multiply" }}
              ></path>
              <path
                fill="#595A62"
                d="M148.614 201.487a9.521 9.521 0 1 0 0-19.043 9.521 9.521 0 0 0 0 19.043"
              ></path>
              <path
                fill="#F5F5F5"
                d="M148.614 195.397a3.432 3.432 0 1 0-.001-6.863 3.432 3.432 0 0 0 .001 6.863"
              ></path>
              <path
                fill="#595A62"
                d="M54.873 201.487c5.258 0 9.521-4.263 9.521-9.521s-4.263-9.521-9.52-9.521c-5.259 0-9.522 4.263-9.522 9.521s4.263 9.521 9.521 9.521"
              ></path>
              <path
                fill="#F5F5F5"
                d="M54.873 195.397a3.432 3.432 0 1 0 0-6.864 3.432 3.432 0 0 0 0 6.864"
              ></path>
              <path
                fill="#595A62"
                d="M54.873 203.081a11.121 11.121 0 1 1 4.255-21.394 11.12 11.12 0 0 1-4.255 21.394m0-19.063a7.941 7.941 0 0 0-5.616 13.559 7.95 7.95 0 0 0 8.656 1.722 7.94 7.94 0 0 0 4.903-7.338 7.94 7.94 0 0 0-7.943-7.927zM148.63 203.081a11.12 11.12 0 0 1-10.274-6.865 11.12 11.12 0 1 1 10.274 6.865m0-19.063a7.94 7.94 0 0 0-7.791 9.492 7.946 7.946 0 0 0 10.83 5.789 7.94 7.94 0 0 0 4.903-7.338 7.94 7.94 0 0 0-7.942-7.927zM59.577 34.71l6.742-6.741 2.246 2.246-6.742 6.742zM51.268 21.303h3.177v9.536h-3.177zM65.972 41.524h9.537v3.178h-9.537z"
              ></path>
              <path
                fill="#F5F5F5"
                d="M73.01 64.834H31.584l24.856 92.099h31.718z"
              ></path>
              <path
                fill="#595A62"
                d="M195.826 158.526H86.809L71.103 63.033 203.26 77.224zm-106.317-3.177h103.415l6.884-75.297L74.916 66.634z"
              ></path>
              <path
                fill="#595A62"
                d="M90.028 158.526H55.223l-25.735-95.28h44.85zm-32.37-3.177H86.29L71.659 66.422H33.661z"
              ></path>
              <path
                fill="#595A62"
                d="M76.69 66.422H30.906L10.743 45.39h46.121zm-44.427-3.177h37.066L55.493 48.567h-37.3z"
              ></path>
              <path
                fill="#595A62"
                d="M52.3 52.427H5.284a5.454 5.454 0 0 1 0-10.903H52.3a5.454 5.454 0 0 1 0 10.903M5.284 44.68a2.277 2.277 0 0 0 0 4.549H52.3a2.277 2.277 0 0 0 0-4.549z"
              ></path>
              <path
                fill="#595A62"
                d="M52.3 43.092H5.284a3.865 3.865 0 0 0 0 7.725H52.3a3.865 3.865 0 0 0 0-7.725M89.747 193.554H54.852v-36.621h3.177v33.444H86.57v-33.444h3.177z"
              ></path>
              <path
                fill="#595A62"
                d="M180.941 193.554h-94.37v-36.621h3.176v33.444h91.194z"
              ></path>
              <path
                fill="#595A62"
                d="M88.159 201.486c5.258 0 9.52-4.263 9.52-9.521s-4.262-9.521-9.52-9.521-9.52 4.263-9.52 9.521 4.262 9.521 9.52 9.521M180.946 201.486a9.521 9.521 0 1 0 0-19.043 9.521 9.521 0 0 0 0 19.043"
              ></path>
              <path
                fill="#595A62"
                d="M88.159 203.081a11.13 11.13 0 0 1-10.274-6.865 11.122 11.122 0 0 1 14.53-14.529 11.121 11.121 0 0 1-4.256 21.394m0-19.063a7.94 7.94 0 0 0-7.79 9.492 7.943 7.943 0 0 0 15.733-1.549 7.94 7.94 0 0 0-7.943-7.927zM180.93 203.081a11.12 11.12 0 1 1 .003-22.239 11.12 11.12 0 0 1-.003 22.239m0-19.063a7.944 7.944 0 1 0 3.04 15.281 7.93 7.93 0 0 0 3.564-2.926 7.94 7.94 0 0 0 1.339-4.412 7.94 7.94 0 0 0-7.943-7.927z"
              ></path>
              <path
                fill="#F5F5F5"
                d="M88.127 195.397a3.432 3.432 0 1 0-.001-6.864 3.432 3.432 0 0 0 .001 6.864M180.946 195.397a3.432 3.432 0 1 0-.001-6.863 3.432 3.432 0 0 0 .001 6.863"
              ></path>
              <path
                fill="#9596A4"
                d="M106.946 86.984a6.465 6.465 0 0 1 5.894 6.444v44.109a3.71 3.71 0 0 1-6 2.738 3.7 3.7 0 0 1-1.355-2.277l-5.528-43.76a6.47 6.47 0 0 1 4.153-6.869 6.5 6.5 0 0 1 2.836-.385M144.51 90.839a6.49 6.49 0 0 1 5.888 7.053l-3.643 39.952a3.705 3.705 0 0 1-3.786 3.627 3.706 3.706 0 0 1-3.627-3.786l-1.895-40.053a6.483 6.483 0 0 1 4.329-6.452 6.5 6.5 0 0 1 2.734-.341"
              ></path>
              <path
                fill="#595A62"
                d="M6.428 199.903h174.518v3.177H6.428z"
              ></path>
              <path
                fill="#9596A4"
                d="M114.846 59.454v-3.177c.927.066 1.859.007 2.77-.175l.778 3.077c-1.163.26-2.358.352-3.548.275m-5.295 0h-3.177v-3.177h3.177zm14.011-3.865-2.61-1.806a5.75 5.75 0 0 0 .942-2.288l3.13.53a8.85 8.85 0 0 1-1.462 3.564m-2.414-8.091a5.9 5.9 0 0 0-1.705-1.795l1.789-2.648a9 9 0 0 1 2.648 2.78zm-5.825-2.796h-3.177v-3.177h3.177zm-8.472 0a17.6 17.6 0 0 1-3.453-.159l.567-3.13c.956.128 1.922.165 2.886.112zM97.95 41.62a10.1 10.1 0 0 1-2.06-3.13l2.928-1.223a6.9 6.9 0 0 0 1.414 2.145zm.529-8.578-3.098-.704c.279-1.23.787-2.396 1.499-3.437l2.648 1.79a6.9 6.9 0 0 0-1.091 2.34zm4.369-4.962-1.139-2.991a10.1 10.1 0 0 1 3.664-.62v3.177a7 7 0 0 0-2.568.434zm33.28-.423V24.48a13 13 0 0 0 2.843-.122l.646 3.113a15.7 15.7 0 0 1-3.532.186zm-5.295 0h-3.178V24.48h3.178zm-8.473 0h-3.177V24.48h3.177zm-8.472 0h-3.177V24.48h3.177zm31.035-3.559-2.536-1.916a5.76 5.76 0 0 0 1.059-2.24l3.103.677a8.9 8.9 0 0 1-1.626 3.48m-1.366-9.102c0-.053-.249-.032 3.177-.032z"
              ></path>
              <path
                fill="url(#paint1_linear_291_3070)"
                d="M145.119 13.154a7.47 7.47 0 0 1 4.615-6.908 7.5 7.5 0 0 1 2.862-.57h5.756a7.48 7.48 0 0 1-7.483 7.477z"
                style={{ mixBlendMode: "multiply" }}
              ></path>
              <path
                fill="url(#paint2_linear_291_3070)"
                d="M145.119 13.154a7.47 7.47 0 0 0-4.615-6.908 7.5 7.5 0 0 0-2.862-.57h-5.756a7.48 7.48 0 0 0 7.483 7.477z"
                style={{ mixBlendMode: "multiply" }}
              ></path>
              <path
                fill="#595A62"
                d="M150.87 14.742h-7.339v-1.589a9.074 9.074 0 0 1 9.065-9.065h7.344v1.588a9.075 9.075 0 0 1-9.07 9.066m-3.945-3.177h3.945a5.905 5.905 0 0 0 5.703-4.3h-3.951a5.9 5.9 0 0 0-5.697 4.3"
              ></path>
              <path
                fill="#595A62"
                d="M146.708 14.742h-7.34a9.076 9.076 0 0 1-9.07-9.066V4.088h7.344a9.074 9.074 0 0 1 9.066 9.065zm-13.016-7.477a5.905 5.905 0 0 0 5.676 4.3h3.967a5.9 5.9 0 0 0-5.672-4.3z"
              ></path>
              <path
                fill="url(#paint3_linear_291_3070)"
                d="M184.637 16.892a7.47 7.47 0 0 1-2.196-5.295 7.48 7.48 0 0 1 2.196-5.296l4.072-4.072a7.495 7.495 0 0 1 1.624 8.161 7.5 7.5 0 0 1-1.624 2.43z"
                style={{ mixBlendMode: "multiply" }}
              ></path>
              <path
                fill="url(#paint4_linear_291_3070)"
                d="M184.637 16.892a7.48 7.48 0 0 0-8.162-1.627 7.5 7.5 0 0 0-2.428 1.627l-4.067 4.066a7.47 7.47 0 0 0 5.295 2.2 7.47 7.47 0 0 0 5.295-2.2z"
                style={{ mixBlendMode: "multiply" }}
              ></path>
              <path
                fill="#595A62"
                d="m184.637 19.137-1.123-1.123a9.07 9.07 0 0 1-2.657-6.412 9.06 9.06 0 0 1 2.657-6.413L188.709 0l1.122 1.123a9.06 9.06 0 0 1 2.658 6.412 9.07 9.07 0 0 1-2.658 6.413zm3.918-14.493-2.79 2.796a5.89 5.89 0 0 0-.959 7.058l2.791-2.79a5.9 5.9 0 0 0 .969-7.064z"
              ></path>
              <path
                fill="#595A62"
                d="m168.884 22.08-1.123-1.122 5.189-5.19a9.07 9.07 0 0 1 9.884-1.966c1.1.456 2.1 1.124 2.941 1.967l1.123 1.122-5.195 5.19a9.04 9.04 0 0 1-6.409 2.661 9.05 9.05 0 0 1-6.41-2.661m3.521-1.27a5.88 5.88 0 0 0 7.053-.974l2.791-2.791a5.895 5.895 0 0 0-7.053.969z"
              ></path>
              <path
                fill="#9596A4"
                d="m203.271 66.422-.069-3.177a6.7 6.7 0 0 0 2.494-.53l1.255 2.918a9.9 9.9 0 0 1-3.68.79m-49.643-1.588h-3.177v-3.177h3.177zm43.717-.228a9.9 9.9 0 0 1-2.6-2.716l2.648-1.721a6.8 6.8 0 0 0 1.769 1.848zm14.25-3.092-2.743-1.589c.431-.74.715-1.556.836-2.404l3.146.45a9.8 9.8 0 0 1-1.239 3.543m-57.967-5.152h-3.177a12.3 12.3 0 0 1 .313-3.548l3.076.789a9.8 9.8 0 0 0-.212 2.759m42.722-.376h-3.177a8 8 0 0 0-.286-2.648l3.039-.927a11 11 0 0 1 .424 3.575m16.584-3.543h-3.177v-3.177h3.177zm-56.822-2.383-2.002-2.467a9.9 9.9 0 0 1 3.32-1.769l.932 3.034a6.7 6.7 0 0 0-2.25 1.202m34.339-.185a6.6 6.6 0 0 0-2.298-1.096l.794-3.077a9.6 9.6 0 0 1 3.394 1.62zm-7.175-1.308h-3.177V45.39h3.177zm-8.472 0h-3.177V45.39h3.177zm-8.473 0h-3.177V45.39h3.177zm46.598-4.596h-3.177v-3.177h3.177zm0-8.472h-3.177V32.32h3.177zm-3.495-7.943a6.6 6.6 0 0 0-1.223-2.235l2.441-2.033a9.8 9.8 0 0 1 1.811 3.304zm-4.765-4.47a10.4 10.4 0 0 0-2.786-.19V19.72a13 13 0 0 1 3.537.28zm-8.081-.19h-3.177V19.72h3.177z"
              ></path>
              <path
                fill="#595A62"
                d="M38.909 153.835a4.83 4.83 0 0 1-4.016-2.141 4.835 4.835 0 0 1 7.41-6.135 4.825 4.825 0 0 1 1.086 5.279 4.83 4.83 0 0 1-4.48 2.997m0-6.492a1.66 1.66 0 0 0-1.533 1.026 1.657 1.657 0 1 0 1.532-1.026M22.102 178.945a6.595 6.595 0 0 1-6.09-4.061 6.6 6.6 0 0 1 8.578-8.648 6.597 6.597 0 0 1-2.488 12.709m0-10.014a3.422 3.422 0 1 0 3.42 3.395 3.427 3.427 0 0 0-3.42-3.395"
              ></path>
              <path
                fill="#F5F5F5"
                d="M203.339 173.973c17.161 0 31.072-13.911 31.072-31.072 0-17.16-13.911-31.072-31.072-31.072-17.16 0-31.072 13.912-31.072 31.072 0 17.161 13.912 31.072 31.072 31.072"
              ></path>
              <path
                fill="#595A62"
                d="M203.339 175.566a32.67 32.67 0 0 1-30.183-20.161A32.667 32.667 0 1 1 236 142.9a32.696 32.696 0 0 1-32.661 32.666m0-62.149a29.49 29.49 0 0 0-27.248 18.201 29.5 29.5 0 0 0-1.679 17.039 29.48 29.48 0 0 0 23.17 23.171 29.49 29.49 0 0 0 35.241-28.928 29.52 29.52 0 0 0-29.484-29.483"
              ></path>
              <path
                fill="#CA2F2E"
                d="m186.642 150.923 24.708-24.708 8.368 8.367-24.709 24.708z"
              ></path>
              <path
                fill="#CA2F2E"
                d="m186.646 134.581 8.367-8.367 24.708 24.708-8.367 8.367z"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear_291_3070"
                  x1="163.758"
                  x2="199.257"
                  y1="130.251"
                  y2="130.251"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#CACFDD"></stop>
                  <stop offset="1" stopColor="#EEF0F1"></stop>
                </linearGradient>
                <linearGradient
                  id="paint1_linear_291_3070"
                  x1="145.119"
                  x2="158.352"
                  y1="9.415"
                  y2="9.415"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#CACFDD"></stop>
                  <stop offset="1" stopColor="#EEF0F1"></stop>
                </linearGradient>
                <linearGradient
                  id="paint2_linear_291_3070"
                  x1="131.886"
                  x2="145.119"
                  y1="9.415"
                  y2="9.415"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#CACFDD"></stop>
                  <stop offset="1" stopColor="#EEF0F1"></stop>
                </linearGradient>
                <linearGradient
                  id="paint3_linear_291_3070"
                  x1="182.672"
                  x2="192.067"
                  y1="13.759"
                  y2="4.364"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#CACFDD"></stop>
                  <stop offset="1" stopColor="#EEF0F1"></stop>
                </linearGradient>
                <linearGradient
                  id="paint4_linear_291_3070"
                  x1="173.277"
                  x2="182.672"
                  y1="23.154"
                  y2="13.759"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#CACFDD"></stop>
                  <stop offset="1" stopColor="#EEF0F1"></stop>
                </linearGradient>
              </defs>
            </svg>
            <Box className="text-4xl font-bold color-lightblack">
              Your cart is empty
            </Box>
            <p className="text-lg font-medium text-center color-lightblack">
              There is nothing in your bag, <br />
              Let’s add some items
            </p>
            <button
              onClick={() => {
                navigate("/");
              }}
              className="mt-4 px-3 py-1 text-base min-w-[218px] btn-green  text-white rounded-xl"
            >
              Continue Shopping
            </button>
          </Box>
        )}
        {cartItems?.length > 0 && (
          <Grid className="flex justify-between cartwrapper" container>
            <Grid className="mt-1 mb-1 itemwrap" item xs={7.5}>
              {/* <div className="px-2 py-2 flex border-solid border-2 border-gray justify-between items-center rounded-md">
                <div className="pl-2 flex flex-col ">
                  <span className="text-sm text-secondary font-bold tracking-wider capitalize">Deliver to: John Doe, 480001</span>
                  <span className="text-xs tracking-wider">John Doe
                    P.O. Box 283 8562 Fusce Rd.
                    Frederick Nebraska 20620</span>
                  <span className="text-xs tracking-wider">Estimated Delivery: 5days</span>
                </div>
                <div className="pr-3">
                  <button className="text-secondary text-sm uppercase font-bold">Change</button>
                </div>
              </div> */}
              <Accordion activeIndex={0}>
                <AccordionTab
                  header={
                    <p className="md:text-3xl color-lightblack text-lg font-bold pb-2 px-6">
                      My Cart
                    </p>
                  }
                >
                  <table className="cart-item-table w-full">
                    <thead className="bg-[#F4F5F6]">
                      <tr className="text-lg font-medium">
                        <th>Item</th>
                        <th>Quantity</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((product) => (
                        <CartCard
                          key={product.catalogId}
                          product={product}
                          fetchCartItems={fetchCartItems}
                        />
                      ))}
                    </tbody>
                  </table>
                </AccordionTab>
                <AccordionTab
                  header={
                    <p className="md:text-3xl color-lightblack text-lg font-bold pb-2 px-6">
                      My Address
                    </p>
                  }
                >
                  {address?.map((address) => (
                    <div
                      className={`flex items-center p-4 border rounded-md mb-3 cursor-pointer ${
                        selectedAddress?._id === address?._id
                          ? "border-[#3C881F] bg-[#E2EDDD]"
                          : "border-[#EFEFF1]"
                      }`}
                      onClick={() => handleOptionAddress(address)}
                    >
                      <input
                        type="radio"
                        name="payment-method"
                        value="Online"
                        checked={selectedAddress?._id === address?._id}
                        onChange={() => handleOptionAddress(address)}
                        className="mr-2 cursor-pointer"
                      />
                      <div className="address-card bg-white border border-[#EFEFF1] rounded-3xl py-4 px-6 mb-4 md:w-[70%] w-full">
                        <div className="card-top flex justify-between items-center border-b border-[#EFEFF1] pb-3 mb-4">
                          <div className="title font-semibold color-lightblack md:text-base text-sm">
                            Default Address
                          </div>
                          <button className="editbtn text-[#CD1318] font-semibold flex items-center justify-self-end">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              fill="none"
                              viewBox="0 0 15 15"
                              className="mr-1"
                            >
                              <path
                                fill="#CD1318"
                                d="M11.196 7.928a.59.59 0 0 0-.589.59v4.714a.59.59 0 0 1-.59.589h-8.25a.59.59 0 0 1-.588-.59v-8.25a.59.59 0 0 1 .589-.589h4.714a.59.59 0 1 0 0-1.178H1.768A1.77 1.77 0 0 0 0 4.982v8.25A1.77 1.77 0 0 0 1.768 15h8.25a1.77 1.77 0 0 0 1.768-1.768V8.517a.59.59 0 0 0-.59-.589"
                              ></path>
                              <path
                                fill="#CD1318"
                                d="M5.877 6.967a.3.3 0 0 0-.083.155l-.431 2.156a.306.306 0 0 0 .359.359l2.155-.431a.3.3 0 0 0 .156-.084l4.824-4.824-2.155-2.155zM14.584.416a1.423 1.423 0 0 0-2.011 0l-.787.787 2.01 2.011.788-.787A1.4 1.4 0 0 0 15 1.422c0-.38-.148-.738-.416-1.006"
                              ></path>
                            </svg>
                            Edit
                          </button>
                        </div>
                        <div className="address-detail">
                          <p className="text-[#6E6F72]">
                            {address?.addressFirstLine}
                            <br />
                            Pin - {address?.pincode}
                            <br />
                          </p>
                          <p className="color-lightblack font-semibold">
                            {address?.fullName} <br /> Mobile: 
                            {address?.mobileNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <button
                    data-popover-target="menu"
                    className="btn-address md:rounded-xl rounded-[5px] py-2 mb-1 px-4 bg-[#CD1318] border border-transparent md:text-lg text-[10px] flex items-center justify-center min-w-[148px] text-white transition-all hover:bg-transparent hover:border-[#CD1318] hover:text-[#CD1318]"
                    type="button"
                    onClick={showAddressPopup}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="23"
                      fill="none"
                      viewBox="0 0 22 23"
                      className="mr-2"
                    >
                      <path
                        fill="#fff"
                        d="M11 .5a11 11 0 1 0 11 11 11.013 11.013 0 0 0-11-11m5 12h-4v4a1 1 0 0 1-2 0v-4H6a1 1 0 0 1 0-2h4v-4a1 1 0 0 1 2 0v4h4a1 1 0 0 1 0 2"
                      ></path>
                    </svg>
                    Add New Address
                  </button>
                </AccordionTab>
                <AccordionTab
                  header={
                    <p className="md:text-3xl color-lightblack text-lg font-bold pb-2 px-6">
                      Payment Method
                    </p>
                  }
                >
                  <PaymentGateway />
                </AccordionTab>
              </Accordion>
              {/* <div className="border border-[#EFEFF1] bg-white rounded-3xl py-4">
                <p className="md:text-3xl color-lightblack text-lg font-bold pb-2 px-6">
                  My Cart
                </p>
                <table className="cart-item-table w-full">
                  <thead className="bg-[#F4F5F6]">
                    <tr className="text-lg font-medium">
                      <th>Item</th>
                      <th>Quantity</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((product) => (
                      <CartCard
                        key={product.catalogId}
                        product={product}
                        fetchCartItems={fetchCartItems}
                      />
                    ))}
                  </tbody>
                </table>
              </div> */}
            </Grid>
            <Grid className="mt-1 mb-1 totalwrap" item xs={4}>
              <p className="md:text-3xl text-lg hidden md:block color-lightblack font-bold mt-4">
                Cart Total
              </p>
              <div className="items-totalprice bg-[#FFFFFF] border border-[#EFEFF1] rounded-3xl px-6 py-4">
                <div className="item-price">
                  <span className="label">Subtotal</span>
                  <span className="price">
                    ₹{Math.round(checkoutCost?.cartTotal || 0)}
                  </span>
                </div>
                <div className="item-price">
                  <span className="label">Tax</span>
                  <span className="price">
                    ₹{Math.round(checkoutCost?.taxTotal || 0)}
                  </span>
                </div>
                <div className="item-price">
                  <span className="label">Shipping</span>
                  <span className="price">
                    ₹{Math.round(checkoutCost?.shippingTotal || 0)}
                  </span>
                </div>
                <div className="item-price">
                  <span className="label font-bold">TOTAL</span>
                  <span className="price font-bold">
                    ₹{Math.round(checkoutCost?.finalTotal || 0)}
                  </span>
                </div>
                <div className="item-price">
                  <span className="label">Your Savings</span>
                  <span className="price">
                    ₹{Math.round(checkoutCost?.discountTotal || 0)}
                  </span>
                </div>
                <button
                  disabled={!cartItems?.length} // Disable if cart is empty
                  className="btn-green mt-6 w-full flex items-center"
                  id="rzp-button1"
                  onClick={razorpayCheckout}
                >
                  Proceed to Checkout
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="14"
                    fill="none"
                    viewBox="0 0 27 14"
                    className="ml-2"
                  >
                    <path
                      fill="#fff"
                      d="M26.69 5.793V5.79L21.179.307a1.055 1.055 0 1 0-1.488 1.495l3.7 3.682H1.055a1.055 1.055 0 1 0 0 2.11H23.39l-3.7 3.682a1.055 1.055 0 0 0 1.488 1.495l5.51-5.484.002-.001a1.056 1.056 0 0 0 0-1.493"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="flex items-center font-semibold color-lightblack mt-3 md:text-base text-[10px] textwrap">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="34"
                  fill="none"
                  viewBox="0 0 27 34"
                  className="mr-2"
                >
                  <path
                    fill="#3C881F"
                    fillRule="evenodd"
                    d="M13.226 34a.45.45 0 0 1-.199-.053c-.033-.02-3.273-1.866-6.474-4.555-1.885-1.587-3.393-3.16-4.475-4.667C.698 22.799 0 20.966 0 19.267V4.938c0-.166.106-.318.266-.378L13.094.025a.37.37 0 0 1 .265 0L26.187 4.56c.16.053.265.206.265.378v14.329c0 1.7-.697 3.538-2.078 5.457-1.082 1.508-2.59 3.081-4.475 4.668-3.2 2.69-6.44 4.535-6.474 4.555a.45.45 0 0 1-.199.053m0-2.855a.45.45 0 0 1-.199-.053 40.5 40.5 0 0 1-5.371-3.778c-3.612-3.041-5.452-5.883-5.452-8.44V7.01c0-.166.107-.319.266-.379L13.094 2.88a.37.37 0 0 1 .265 0L23.983 6.63c.159.053.265.206.265.379v11.865c0 2.556-1.832 5.398-5.451 8.439a40.5 40.5 0 0 1-5.372 3.778.4.4 0 0 1-.199.053M3.001 7.289v11.586c0 5.139 8.791 10.564 10.225 11.414 1.434-.85 10.225-6.275 10.225-11.414V7.289L13.226 3.677z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fill="#fff"
                    d="M19.581 12.265a1.073 1.073 0 0 0-1.517 0l-7.256 7.26-2.404-2.405a1.072 1.072 0 1 0-1.517 1.517l3.162 3.161c.346.346.882.414 1.303.167q.024-.015.044-.029.09-.06.17-.138l8.015-8.016a1.07 1.07 0 0 0 0-1.517"
                  ></path>
                </svg>
                100% Secure Payment
              </div>
              {/* <div className="border-solid border-2 border-gray rounded-sm flex flex-col">
                <div className="pl-5 py-3 flex items-center text-sm font-bold uppercase bg-secondary text-white rounded-sm tracking-wider">
                  Price Summary
                </div>
                <div className="mt-4 px-3" >
                  <div className="flex justify-between text-sm">
                    <span className="">Total MRP (Incl. of taxes) </span>
                    <span>₹{checkoutCost?.cartTotal || 0}</span>
                  </div>
                  <div className="flex justify-between mt-2 text-sm">
                    <span className="">Delivery Fee </span>
                    <span className="text-green">₹{checkoutCost?.shippingTotal|| 0}</span>
                  </div>
                  <div className="flex justify-between mt-2 text-sm">
                    <span className="">Total Discount </span>
                    <span> ₹{checkoutCost?.discountTotal|| 0}</span>
                  </div>
                  <div className="flex justify-between mt-2 text-sm">
                    <span className="">Tax </span>
                    <span> ₹{checkoutCost?.taxTotal|| 0}</span>
                  </div>
                  <div className="flex justify-between mt-2 text-md font-bold">
                    <span className="">Subtotal </span>
                    <span>₹{checkoutCost?.finalTotal|| 0}</span>
                  </div>
                  <div className="mt-4 mb-3 flex justify-between items-center">
                    <div className=" inline-flex flex-col content-center">
                      <span className="text-sm text-center w-full">Total</span>
                      <span className="text-lg font-bold text-center w-full">₹{checkoutCost?.finalTotal|| 0}</span>
                    </div>
                    <button id="rzp-button1" onClick={handleOpen} className=" px-5 py-2 bg-secondary text-white font-bold tracking-wider uppercase rounded-md"> checkout</button>
                  </div>
                </div>
              </div> */}
            </Grid>
          </Grid>
        )}
      </Container>
      <Dialog
        draggable={false}
        visible={visible}
        blockScroll={true}
        style={{ width: "35vw" }}
        onHide={handleHide}
        className="bg-white p-popup"
      >
        <LocationPopup isSignUp={false}/>
      </Dialog>
    </>
  );
};

export default CartPage;
