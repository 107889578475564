import { useSearchParams } from "react-router-dom";

const AccordionItem = ({ label,id }) => {
  const [searchParams, setSearchParams] = useSearchParams([]);
    return (
      <div className={`${searchParams.get("q") == id ? "active":""} accorditem flex justify-start items-center cursor-pointer`}>
        {/* <input type="checkbox" className="text-primary border-gray-light rounded" /> */}
        <label className="md:text-lg text-[8px] color-lightblack w-full md:pl-12">{label}</label>
      </div>
    );
  };
  
  export default AccordionItem;
  