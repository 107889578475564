import axios from "axios";

const api_endpoint = process.env.REACT_APP_API_END_POINT;
const node_env = process.env.NODE_ENV;

console.log("node_env", node_env);

const axiosClient = axios.create({
  baseURL: api_endpoint,
  headers: {
    "Content-Type": "application/json",
  },
});

// Store all active requests for cancellation
const pendingRequests = new Map<string, Function>();

// Request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    const url = config.url || "";
    const token = localStorage.getItem("accessToken");

    // Cancel any duplicate pending request
    if (pendingRequests.has(url)) {
      const cancel = pendingRequests.get(url);
      if (cancel) cancel("Duplicate request canceled");
      pendingRequests.delete(url);
    }

    // Create a cancel token for the new request
    config.cancelToken = new axios.CancelToken((cancel) => {
      pendingRequests.set(url, cancel);
    });

    // Add authorization headers based on the context
    if (url.includes("login") || url.includes("signup")) {
      config.headers["Authorization"] = process.env.REACT_APP_BASIC_TOKEN;
    } else if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      const guestToken = localStorage.getItem("guestToken");
      if (guestToken) {
        config.headers["Authorization"] = `Bearer ${guestToken}`;
      } else {
        config.headers["Authorization"] = process.env.REACT_APP_BASIC_TOKEN;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    // Clear the request from the pending list once it's completed
    const url = response.config.url || "";
    pendingRequests.delete(url);

    return response;
  },
  async (error) => {
    const url = error.config.url || "";
    pendingRequests.delete(url); // Remove the request from the pending list on error

    if (error.response) {
      if (error.response.status === 401) {
        console.log("Unauthorized - clearing tokens and redirecting");
        localStorage.clear();
        // Optionally redirect after a delay
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      }
    }

    return Promise.reject(error);
  }
);

// Cancel all pending requests
export const cancelAllRequests = () => {
  pendingRequests.forEach((cancel) => {
    if (cancel) cancel("Request canceled globally");
  });
  pendingRequests.clear();
};

export default axiosClient;
