import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import styles from "./SearchBar.module.css";

const SearchBar = () => {
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();

  // Debounced API call
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const fetchSearchResults = (query) => {
    console.log("Fetching API with query:", query);
    // Replace this with your API call logic
    // Example: axios.get(`/api/search?query=${query}`)
  };

  const debouncedFetch = useCallback(debounce(fetchSearchResults, 500), []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchKey(value);
    if (value.trim()?.trim()?.length > 3) {
      debouncedFetch(value);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (searchKey?.trim()?.length > 3) {
      navigate(`/products?search=${encodeURIComponent(searchKey)}`);
    }
  };

  return (
    <>
      <form className={styles.container} onSubmit={handleFormSubmit}>
        <div className="relative">
          <div className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray left-3">
            <Icon icon="bitcoin-icons:search-filled" width="24" height="24" />
          </div>
          <input
            type="text"
            size={50}
            placeholder="Search for products"
            className="w-full py-2 pl-12 pr-4 text-gray border rounded-3xl outline-none bg-white focus:bg-white focus:border-blue-light"
            value={searchKey}
            onChange={handleInputChange}
          />
        </div>
      </form>
    </>
  );
};

export default SearchBar;
