import { Container, Grid } from "@mui/material";
import Pagination from "../../../components/pagination/Pagination";
import FilterSidebar from "../../../components/product-filter/FilterSidebar";
import ProductCard from "../../../components/product-card/ProductCard";
import { useEffect, useState } from "react";
import { getCatalogList } from "../../../api/lib/product";
import { useAuth } from "../../../context/AuthContext";
import ProductListShimmer from "../../../components/shimmer-ui-components/ProductListShimmer";
import { useSearchParams } from "react-router-dom";
import SortingBar from "../../../components/product-filter/SortingBar";
import { ENUM, SORTING } from "../../../constant/enum";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { MenuItem } from "primereact/menuitem";
import { CLIENT_ROUTES } from "../../../constant/client_route";

const ProductCardContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get("q") || ""; // Fetch the `q` parameter
  const brandId = searchParams.get("brand") || "";
  const pageNumber = parseInt(searchParams.get("page")) || 1;
  const pageLimit = parseInt(searchParams.get("pageSize")) || 15;
  const search = searchParams.get("search") || "";
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [catalog, setCatalog] = useState([]);
  const { currentUser, token } = useAuth();

  const [sortingOption, setSortingOption] = useState(ENUM.SORT_BY.CREATED_DATE);
  const [sortingOrder, setSortingOrder] = useState(SORTING.ASCENDING);

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const updateBreadcrumb = (newItem) => {
    setBreadcrumbItems((prevItems) => {
      const filteredItems = prevItems.filter((item) => item.type !== "category");
      // Add the new category item
      return [...filteredItems, newItem];
    });
};

const handleSortChange = ({ field, order }) => {
  setSortingOption(field); // 'price'
  setSortingOrder(order);  // 'ASC' or 'DESC'
};

useEffect(() => {
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await getCatalogList({
        page: pageNumber,
        limit: pageLimit,
        categoryId: categoryId || null,
        brandCodes: brandId || null,
        sortOrder: sortingOrder,  // 'ASC' or 'DESC'
        sortedBy: sortingOption,  // 'price' or other
        search:search
      });
      setCatalog(response.data.data.list);
      setTotalPages(response.data.data.totalPages);
    } catch (error) {
      console.error("Error Fetching Data", error);
    } finally {
      setLoading(false);
      window.scrollTo(0, 0);
    }
  };
  fetchProducts();
}, [token, pageNumber, pageLimit, categoryId, brandId, sortingOption, sortingOrder]);


const handlePageChange = (page: number) => {
  const updatedSearchParams = new URLSearchParams(searchParams);
  updatedSearchParams.set("page", page.toString());
  setSearchParams(updatedSearchParams);
};

// const items: MenuItem[] = [{ label: 'Electronics' }, { label: 'Computer' }, { label: 'Accessories' }, { label: 'Keyboard' }, { label: 'Wireless' }];
// const home: MenuItem = { label:'Home', url: CLIENT_ROUTES.HOME }

return (
  <Container
    maxWidth={"lg"}
    sx={{ marginTop: 5 }}
    className="wrap-productlist"
  >
    {/* <Breadcrumb items={items} home={home}/> */}
    <Breadcrumb items={breadcrumbItems} home={{ label: "Home", url: "/" }} />
    <div className="flex justify-between gap-4">
      <div id="filter-sidebar" className="w-64 sidebar-filter-wrapper">
        {/* <FilterSidebar /> */}
        <FilterSidebar onCategorySelect={updateBreadcrumb} />
      </div>
      <div className="product-list-rightwrap w-full">
        <div className="flex flex-col w-full productwrap-inner">
          <div id="sort-bar" className="mb-4 sortbar-wrapper">
            <SortingBar onSortChange={handleSortChange} />
          </div>
          <div className="product-listing w-full">
            <div className="flex flex-col w-full">
              {/* <div id="sort-bar" className="mb-3">
                  <SortingBar />
                </div> */}
              <Grid
                container
                columns={20}
                className="flex justify-start items-stretch gap-[1rem] grid-items"
              >
                {catalog?.map((product, index) => (
                  <ProductCard key={index} product={product} />
                ))}
              </Grid>
              <div id="pagination-ribbon" className="">
                <Pagination
                  currentPage={pageNumber}
                  totalPageCount={totalPages}
                  pageLimit={pageLimit}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);
};

export default ProductCardContainer;
