import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { guestUserLogin } from "../../api/lib/user";
import { useAuth } from "../../context/AuthContext";

const Logout: React.FC = () => {
  const { currentUser, logout, setCurrentUser, setToken } = useAuth();
  const navigate = useNavigate();
  const toast = useRef(null);

  useEffect(() => {
    logoutUser();
  }, []);

  const createGuestLogin = async () => {
    try {
      if (!localStorage.getItem("guestToken")) {
        const response = await guestUserLogin();
        const accessToken = response.data.data.accessToken;
        localStorage.setItem("guestId", response.data.data.userId);
        localStorage.setItem("isGuest", response.data.data.isGuest);
        localStorage.setItem("guestToken", accessToken);
        return;
      } else {
        return;
      }
    } catch (error) {
      console.error("Error creating guest login", error);
      return;
    }
  };
  const logoutUser = async () => {
    try {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("authUser");
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Logout",
          detail: "Successful",
          life: 3000,
        });
      }
      await createGuestLogin();

      setCurrentUser(null);
      setToken(null);
      navigate("/");
    } catch (error) {
      console.error("Error creating guest login", error);
    }
  };

  return (
    <>
      <Toast ref={toast}></Toast>
      Logging Out ....!
    </>
  );
};

export default Logout;
