import React, { useState } from 'react';
import { Container } from '@mui/material';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndCondirtions from './TermsAndCondirtions';
import ReturnPolicy from './ReturnPolicy';
import { Icon } from '@iconify/react';

const HelpAndSupport = () => {
  const [activeTab, setActiveTab] = useState('privacy');

  return (
    <Container maxWidth="lg" className="helpandsupport staticpage">
      <h6 className="md:text-4xl text-lg font-semibold color-textred md:mt-10 py-4">
        Help & Support
      </h6>
      <div className="wrap-helpsupport-page bg-white md:mt-6 lg:mb-14 mb-8 border-radius-50 drop-shadow-lg">
        <div className="flex py-2 color-lightblack">
          <div className='tabtitle flex flex-col items-start gap-4 border-r border-[#DEDEE3] py-10 w-3/12'>

            <button
              className={`flex items-center justify-start border-r-2 border-transparent font-semibold text-lg text-black py-2 px-6 w-full  ${activeTab === 'privacy' ? 'color-textred active bg-[#F4F5F6]  border-[#CD1318]' : ''}`}
              onClick={() => setActiveTab('privacy')}
            >
              {/* <Icon className='text-2xl mr-2' icon="material-symbols-light:lock-outline" /> */}
              <svg
                className='mr-2'
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="31"
                fill="none"
                viewBox="0 0 23 31"
              >
                <path
                  fill="#343539"
                  d="M11.433.241c-4.711 0-8.544 3.833-8.544 8.545v4.183c0 .285.23.516.516.516h2.864a.516.516 0 0 0 .516-.516V8.786a4.653 4.653 0 0 1 4.648-4.648 4.653 4.653 0 0 1 4.648 4.648v4.183c0 .285.231.516.516.516h2.864a.516.516 0 0 0 .516-.516V8.786c0-4.712-3.833-8.545-8.544-8.545m7.512 12.212h-1.832V8.786a5.686 5.686 0 0 0-5.68-5.68 5.686 5.686 0 0 0-5.68 5.68v3.667H3.921V8.786c0-4.143 3.37-7.513 7.512-7.513s7.512 3.37 7.512 7.513z"
                ></path>
                <path
                  fill="#343539"
                  d="M20.65 12.453H2.217A2.22 2.22 0 0 0 0 14.67V28.54a2.22 2.22 0 0 0 2.217 2.217H20.65a2.22 2.22 0 0 0 2.216-2.217V14.67a2.22 2.22 0 0 0-2.216-2.217m1.184 16.088c0 .654-.531 1.185-1.184 1.185H2.217a1.186 1.186 0 0 1-1.185-1.185V14.67c0-.654.531-1.185 1.185-1.185H20.65c.653 0 1.184.531 1.184 1.185z"
                ></path>
                <path
                  fill="#343539"
                  d="M13.168 22.282a2.67 2.67 0 0 0 .954-2.048 2.69 2.69 0 0 0-2.69-2.69 2.69 2.69 0 0 0-2.688 2.69c0 .797.35 1.54.954 2.048l-.58 2.763a.516.516 0 0 0 .505.622h3.62a.516.516 0 0 0 .505-.622zm-.85-.654a.52.52 0 0 0-.228.542l.518 2.465h-2.35l.518-2.465a.52.52 0 0 0-.228-.541 1.65 1.65 0 0 1-.772-1.395c0-.914.743-1.657 1.657-1.657.913 0 1.657.743 1.657 1.657 0 .566-.289 1.087-.772 1.394"
                ></path>
              </svg>
              Privacy Policy
            </button>
            <button
              className={`flex justify-start items-center border-r-2 border-transparent text-black font-semibold text-lg py-2 px-6 w-full  ${activeTab === 'terms' ? 'color-textred active bg-[#F4F5F6] border-[#CD1318]' : ''}`}
              onClick={() => setActiveTab('terms')}
            >
              {/* <Icon className='text-2xl mr-2' icon="ep:document" /> */}
              <svg
                className='mr-2'
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="28"
                fill="none"
                viewBox="0 0 21 28"
              >
                <path
                  fill="#343539"
                  d="M20.423 7.528 13.057.162A.55.55 0 0 0 12.665 0H2.287A2.29 2.29 0 0 0 0 2.286v23.429A2.29 2.29 0 0 0 2.286 28h16.013a2.29 2.29 0 0 0 2.286-2.286V7.92a.55.55 0 0 0-.162-.392M13.219 1.89l5.477 5.477h-4.298a1.18 1.18 0 0 1-1.179-1.18zm6.26 23.826c0 .65-.53 1.179-1.18 1.179H2.286a1.18 1.18 0 0 1-1.18-1.18V2.287c0-.65.53-1.18 1.18-1.18h9.826v5.08a2.29 2.29 0 0 0 2.286 2.287h5.08z"
                ></path>
                <path
                  fill="#343539"
                  d="M14.72 14.056H5.864a.553.553 0 0 0 0 1.107h8.854a.553.553 0 1 0 0-1.107M14.72 16.527H5.864a.553.553 0 0 0 0 1.107h8.854a.553.553 0 1 0 0-1.107M14.72 18.999H5.864a.553.553 0 0 0 0 1.107h8.854a.553.553 0 1 0 0-1.107M11.842 21.47H5.865a.553.553 0 0 0 0 1.108h5.977a.553.553 0 1 0 0-1.107"
                ></path>
              </svg>
              Terms And Conditions
            </button>
            <button
              className={`flex justify-start items-center border-r-2 border-transparent text-black font-semibold text-lg py-2 px-6 w-full  ${activeTab === 'return' ? 'color-textred active bg-[#F4F5F6] border-[#CD1318]' : ''}`}
              onClick={() => setActiveTab('return')}
            >
              {/* <Icon className='text-2xl mr-2' icon="tabler:package-import" /> */}
              <svg
                className='mr-2'
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="none"
                viewBox="0 0 28 28"
              >
                <path
                  fill="#343539"
                  d="m4.017 18.142 8.74 2.585a1 1 0 0 0 .277 0l8.74-2.585a.49.49 0 0 0 .35-.467V8.893a.51.51 0 0 0-.328-.46L13.056 5.4a.5.5 0 0 0-.32 0l-8.74 3.033a.5.5 0 0 0-.325.44c-.025.015.013 8.784-.003 8.802a.49.49 0 0 0 .35.467m17.132-.83-7.766 2.296V12.04l7.766-2.48zM12.896 6.375l7.2 2.498-7.2 2.299-7.2-2.299zM4.643 9.56l7.766 2.48v7.568L4.643 17.31z"
                ></path>
                <path
                  fill="#343539"
                  d="m10.333 11.89-3.324-1.04a.48.48 0 0 0-.434.072.49.49 0 0 0-.199.393v1.805a.49.49 0 0 0 .344.465l3.324 1.027a.49.49 0 0 0 .63-.466v-1.792a.49.49 0 0 0-.341-.464M9.7 13.486l-2.35-.725v-.783l2.35.735zM17.748 17.847c.05 0 2.524-.809 2.524-.809a.487.487 0 0 0-.305-.925l-2.372.785a.489.489 0 0 0 .153.949M18.263 16.211c-.014.058 1.964-.634 2.01-.638a.487.487 0 0 0-.306-.924l-1.857.613a.488.488 0 0 0 .153.95"
                ></path>
                <path
                  fill="#343539"
                  d="M24.385 20.74h-1.867a.487.487 0 0 0 0 .975h.925C16.273 30.889.918 25.473.976 13.642c-.001-2.186.564-4.336 1.64-6.239a.487.487 0 1 0-.847-.48A13.64 13.64 0 0 0 0 13.643c-.065 12.51 15.925 18.43 23.898 9.065-.041.34.096.693.487.699a.487.487 0 0 0 .487-.487c.003-.038.016-1.873-.03-1.838a.5.5 0 0 0-.457-.341M13.64.002a13.06 13.06 0 0 0-8.997 3.366v-.526a.487.487 0 0 0-.975 0v1.692a.6.6 0 0 0 .025.121.49.49 0 0 0 .463.366h1.866a.487.487 0 0 0 0-.974h-.68c9.74-8.369 24.41 1.474 20.264 13.756a.487.487 0 0 0 .92.32C29.676 9.496 22.85-.16 13.64.002"
                ></path>
              </svg>
              Return Policy
            </button>
          </div>

          <div className="lg:p-10 md:p-6 p-4 w-9/12 mb-8 tabcontent">
            {activeTab === 'privacy' &&
              (<PrivacyPolicy/>)}
            {activeTab === 'terms' && <p><TermsAndCondirtions/></p>}
            {activeTab === 'return' && <p><ReturnPolicy/></p>}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default HelpAndSupport;
