import React from 'react';
import { Container } from '@mui/material';
import OurstoryImage from '../../assets/ourstory.jpg';

const OurStory = () => {
  return (
    <div className='ourstory-page'>
      <Container maxWidth={"lg"} className='ourstory-section lg:py-12 py-6'>
        <div className='innerwrapper flex md:justify-between gap-4'>
          <div className='lefttext-wrap md:w-[44%] w-full'>
            <h5 className='md:text-lg text-sm font-semibold md:text-left text-center color-textred mb-4'>Our Story</h5>
          
            <p className='font-medium text-sm color-lightblack'>
              At Pushkar Foods, our commitment is to bring the finest and most authentic products for your business. Driven by quality and excellence, 
              we specialize in catering to retailers, hotels, restaurants, and caterers (HoReCa). We provide high-quality products from top multinational brands, 
              ensuring businesses runs smoothly.
            </p>
            <p className='font-medium text-sm color-lightblack'>
              Whether it's sourcing premium ingredients or ensuring rigorous quality control, we take pride in delivering excellence at 
              every step of the process. We are here to meet all your wholesale needs with quality, efficiency, and dependability!
            </p>
          </div>
          <div className='imgwrap md:w-6/12 w-full'>
            <img className="" src={OurstoryImage} alt="OurStory" />  
          </div>
        </div>
      </Container>

      <div className='cardwrapper bg-white py-4 lg:py-8 md:mb-10 mb-4'>
        <div className='custom-container flex md:justify-around'>
          <div className='ourcard bg-[#F4F5F6] lg:p-12 p-4 lg:m-4 text-center color-lightblack rounded-[24px]'>
              <h5 className='font-semibold md:text-2xl mb-4 text-sm'>Our Mission</h5>
              <svg
                className='m-auto'
                xmlns="http://www.w3.org/2000/svg"
                width="148"
                height="148"
                fill="none"
                viewBox="0 0 148 148"
              >
                <circle cx="74" cy="74" r="73.5" fill="#fff" stroke="#EFEFF1"></circle>
                <path
                  fill="#CD1318"
                  d="M84.084 76.521c0 6.951-5.655 12.606-12.605 12.606-6.951 0-12.606-5.655-12.606-12.606 0-6.95 5.655-12.606 12.606-12.606 2.566 0 4.954.773 6.948 2.095l7.31-7.31c-.165-.12-.33-.24-.5-.359-4.957-3.464-9.923-5.215-14.781-5.215-.445 0-.889.015-1.332.044-5.861.399-11.322 3.008-15.372 7.347-4.06 4.343-6.296 10.026-6.296 16.004 0 6.415 2.5 12.447 7.038 16.985s10.57 7.038 16.984 7.038c6.415 0 12.447-2.5 16.985-7.038s7.038-10.57 7.038-16.985c0-5.28-2.133-10.349-5.982-14.477l-7.53 7.53a12.528 12.528 0 012.095 6.947z"
                ></path>
                <path
                  fill="#CD1318"
                  d="M69.697 74.74l5.044-5.044a7.52 7.52 0 00-3.262-.74c-4.173 0-7.567 3.394-7.567 7.566s3.394 7.566 7.567 7.566c4.172 0 7.566-3.394 7.566-7.566a7.522 7.522 0 00-.74-3.263l-5.045 5.044-3.563-3.563z"
                ></path>
                <path
                  fill="#CD1318"
                  d="M96.22 55.343l-3.135 3.135c4.794 5.076 7.455 11.403 7.455 18.043 0 7.76-3.024 15.058-8.514 20.548-5.49 5.49-12.787 8.514-20.547 8.514s-15.058-3.024-20.548-8.514c-5.49-5.49-8.514-12.787-8.514-20.548 0-7.26 2.718-14.166 7.652-19.444 4.924-5.275 11.572-8.448 18.717-8.935 6.459-.427 12.962 1.614 19.338 6.069.413.29.816.587 1.211.89l3.322-3.32.532-9.44c-6.43-4.1-13.9-6.298-21.71-6.298-10.813 0-20.978 4.21-28.623 11.856C35.211 55.544 31 65.709 31 76.52c0 10.812 4.21 20.978 11.856 28.623C50.5 112.789 60.666 117 71.478 117c10.813 0 20.978-4.211 28.623-11.856 7.645-7.646 11.856-17.81 11.856-28.623 0-7.81-2.198-15.28-6.299-21.71l-9.438.532z"
                ></path>
                <path
                  fill="#CD1318"
                  d="M97.792 50.208l10.296-.58L117 40.716 107.284 31l-8.912 8.912-.58 10.296z"
                ></path>
              </svg>
              <div className='mt-4 md:text-sm font-medium text-[8px]'>
                Our mission is to empower businesses by delivering high-quality products with unmatched reliability and service excellence. 
                We are committed to building lasting partnerships with retailers, hotels, restaurants, and caterers (HoReCa) by offering a seamless 
                B2B experience. Through efficient operations, diverse product offerings, and a customer-first approach, we strive to be the trusted 
                partner that fuels the success and growth of every business we serve.
              </div>
          </div>
          <div className='ourcard bg-[#F4F5F6] lg:p-12 p-4 lg:m-4 text-center color-lightblack rounded-[24px]'>
              <h5 className='font-semibold md:text-2xl mb-4 text-sm'>Our Vision</h5>
              <svg
                className='m-auto'
                xmlns="http://www.w3.org/2000/svg"
                width="148"
                height="148"
                fill="none"
                viewBox="0 0 148 148"
              >
                <circle cx="74" cy="74" r="73.5" fill="#fff" stroke="#EFEFF1"></circle>
                <path
                  fill="#CD1318"
                  d="M109.087 109.68l-9.265-20.172-10.046-22.113a1.174 1.174 0 00-.281-.364l-6.247-6.935a.785.785 0 00-1.27.245.763.763 0 00-.037.492l8.3 28.675a.858.858 0 01-.272 1.11l-10.17 10.315a1.39 1.39 0 00-.28 1.665l4.659 9.535c.126.262.325.483.573.636.25.154.537.234.83.231h26.208c.321 0 .629-.126.856-.35a1.188 1.188 0 000-1.692 1.216 1.216 0 00-.856-.351h-1.298a1.443 1.443 0 01-1.404-.927zM80.265 63.59l7.273 25.086a1.526 1.526 0 01-.368 1.474l-10.362 10.402a1.286 1.286 0 00-.272 1.387l4.387 8.842a1.541 1.541 0 01-.093 1.486 1.574 1.574 0 01-1.311.733H35.21a1.22 1.22 0 01-.856-.35 1.188 1.188 0 010-1.692c.227-.225.535-.351.856-.351h3.07a.714.714 0 00.474-.208l12.407-11.494a1.168 1.168 0 00.368-.867l2.632-15.014a.97.97 0 01.465-.867l5.598-4.88a1.513 1.513 0 011.258-.37 1.519 1.519 0 011.076.743l10.432 17.753a1.2 1.2 0 001.628.412c.27-.16.467-.418.545-.72a1.161 1.161 0 00-.129-.888l-8.668-14.65a1.546 1.546 0 010-1.56L77.553 59.47c.177-.206.277-.466.281-.736V36.196a1.203 1.203 0 01.547-.992 1.238 1.238 0 011.138-.117l15.108 6.45a1.22 1.22 0 010 2.21l-13.52 5.686a1.635 1.635 0 00-.662.562 1.608 1.608 0 00-.277.817V63.25c0 .095.097.277.097.373v-.035z"
                ></path>
              </svg>
              <div className='mt-4 md:text-sm font-medium text-[8px]'>
                Our vision is to become the leading and most trusted B2B wholesaler, setting new standards in quality, service, and reliability. 
                We aspire to foster long-term partnerships with businesses across industries, empowering them to thrive through innovative solutions 
                and a seamless procurement experience. By continuously expanding our product range and enhancing customer service, we aim to be the 
                preferred partner across India and beyond.
              </div>
          </div>
        </div>
      </div>
      <div className='custom-container statdata-section'>
        <div className='wrapNumbers flex flex-wrap justify-between md:gap-6 md:py-4 gap-4 py-2'>
          <div className='noItem inline-flex flex-col items-center'>
            <span className='text-7xl font-medium number color-textred '>100+</span>
            <span className='text-xl font-medium text py-4 color-lightblack'>Monthly deliveries</span>
          </div>
          <div className='noItem inline-flex flex-col items-center'>
            <span className='text-7xl font-medium number color-textred '>5+</span>
            <span className='text-xl font-medium text py-4 color-lightblack'>Years of Experience</span>
          </div>
          <div className='noItem inline-flex flex-col items-center'>
            <span className='text-7xl font-medium number color-textred '>500+</span>
            <span className='text-xl font-medium text py-4 color-lightblack'>Satisfied Customers</span>
          </div>
          <div className='noItem inline-flex flex-col items-center'>
            <span className='text-7xl font-medium number color-lightblack color-textred '>100+</span>
            <span className='text-xl font-medium text py-4 color-lightblack'>Cities</span>
          </div>
        </div>
      </div> 
    </div>
  )
}

export default OurStory
