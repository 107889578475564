import React, { useState, useEffect, useContext } from "react";
const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("accessToken"));
  const [signUpByPhone, setSignUpByPhone] = useState(false);
  const [signUpByEmail, setSignUpByEmail] = useState(false);
  const [signUpByGoogle, setSignUpByGoogle] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [forgottenEmail, setForgottenEmail] = useState("");
  const [googleEmail, setGoogleEmail] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [phoneOrEmailEntered, setPhoneOrEmailEntered] = useState(false);
  const [loading, setLoading] = useState(true);

  const [steps, setSteps] = useState(1);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    const checkAuthStatus = () => {
      if (token && authUser) {
        try {
          const parsedUser = JSON.parse(authUser);
          setCurrentUser(parsedUser);
          setLoading(false);
        } catch (error) {
          console.error("Error parsing authUser from localStorage:", error);
          setCurrentUser(null);
          setLoading(false);
        }
      } else {
        setCurrentUser(null);
        setLoading(false);
      }
    };
    checkAuthStatus();
  }, [token]);

  const loginHandler = (userData) => {
    setLoading(true);
    try {
      const accessToken = userData.accessToken;
      setCurrentUser(userData);
      setToken(accessToken);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("authUser", JSON.stringify(userData));
      localStorage.removeItem("guestId");
      localStorage.removeItem("guestToken");
    } catch (error) {
      console.error("Error Logging In", error);
    } finally {
      setLoading(false);
    }
  };

  const logoutHandler = () => {
    setLoading(true);
    try {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("authUser");
      setCurrentUser(null);
      setToken(null);
      window.location.href = '/';
    } catch (error) {
      console.error("Error Logging Out", error);
    } finally {
      setLoading(false);
    }
  };

  const contextValue = {
    currentUser,
    setCurrentUser,
    loading,
    logout: logoutHandler,
    login: loginHandler,
    phoneNumber,
    setPhoneNumber,
    phoneOrEmailEntered,
    setPhoneOrEmailEntered,
    email,
    setEmail,
    signUpByPhone,
    setSignUpByPhone,
    signUpByEmail,
    setSignUpByEmail,
    signUpByGoogle,
    setSignUpByGoogle,
    forgotPassword,
    setForgotPassword,
    forgottenEmail,
    setForgottenEmail,
    googleEmail,
    setGoogleEmail,
    token,
    setToken,
    steps, 
    setSteps
  };
  return (
    <AuthContext.Provider value={contextValue}>
      {loading ? <div>Auth Pending...</div> : children}
    </AuthContext.Provider>
  );
};
export function useAuth() {
  return useContext(AuthContext);
}
