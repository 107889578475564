export const CLIENT_ROUTES = {
    CLIENT_BASE_URL: 'http://localhost:3000/',
    HOME: '/',
    ORDERS: '/profile/orders',
    WISHLIST: '/wishlist',
    CART: '/cart',
    AUTH: {
        LOGIN: '/auth/login',
        REGISTER: '/auth/signup',
        EMAIL_LOGIN: "/auth/login/email",
        VERIFY_OTP: '/auth/login/verify-otp',
        VERIFY_EMAIL: "/auth/login/email/verify-email",
        CHANGE_PASSWORD: "/auth/change-password",
        FORGOT_PASSWORD: "/auth/forgot-password"
    },
    PRODUCT: {
        PRODUCT_LIST: '/products',
        PRODUCT_DETAILS: 'product/:id'
    },
    PROFILE: {
        PROFILE_PAGE: "/profile/my-profile", 
    },
    ADDRESS:{
        ADD_ADDRESS:"/profile/addresses",
        ADDRESS_FORM:"/profile/addresses/address-new"
    },
    STATIC:{
        HELP_AND_SUPPORT:'/help&support',
        FAQ:'/faq',
        DELIVERY_DETAILS:'/delivery',
        OUR_STORY:'/ourstory',
        CONTACT_US:'/contact-us',
        ABOUT:'/about'        
    }
}