import { Container, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useCart } from "../../../context/CartContext";
import { getRequiredProductFromCatalog } from "../../../api/lib/product";
import ProductPageShimmer from "../../../components/shimmer-ui-components/ProductPageShimmer";
import ImageSlider from "./ImageSlider";
import { Product } from "../../../interfaces/product.interfaces";
import { Icon } from "@iconify/react";
import WholeSaleSolution from "../../home/static/WholeSaleSolution";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";

const ProductPage = () => {
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useRef(null);
  const { cartCount, addToCart, updateCartSize } = useCart();
  const { sku } = useParams();

  const [quantity, setQuantity] = useState(1); // Initial quantity

  useEffect(() => {
    fetchProduct();
  }, [sku]);

  const fetchProduct = async () => {
    try {
      setLoading(true);
      const response = await getRequiredProductFromCatalog({ sku: sku });
      setProduct(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error(`Error in fetching product?`, error);
    }
  };

  useEffect(() => {
    setQuantity(product?.quantity || 1);
  }, [product]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!product) {
    return loading ? <ProductPageShimmer /> : <div></div>;
  }

  const handleIncrement = async (e) => {
    try {
      setIsLoading(true);
      e.stopPropagation();
      if (product.isCartAdded) {
        await updateCartSize({
          catalogId: product._id,
          quantity: quantity + 1,
        });
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "Product Added",
            detail: "Successful",
            life: 3000,
          });
        }
        fetchProduct();
      } else {
        setQuantity((prevQuantity) => prevQuantity + 1);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error updating cart", error);
      setIsLoading(false);
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Product Added",
          detail: "Successful",
          life: 3000,
        });
      }
    }
  };

  const handleDecrement = async (e) => {
    try {
      setIsLoading(true);
      e.stopPropagation();
      if (quantity > 1) {
        if (product.isCartAdded) {
          await updateCartSize({
            catalogId: product._id,
            quantity: quantity - 1,
          });
          if (toast.current) {
            toast.current.show({
              severity: "success",
              summary: "Product Added",
              detail: "Successful",
              life: 3000,
            });
          }
          fetchProduct();
        } else {
          setQuantity((prevQuantity) => prevQuantity - 1);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error updating cart", error);
      setIsLoading(false);
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Product Added",
          detail: "Successful",
          life: 3000,
        });
      }
    }
  };

  const addProductToCart = async (e) => {
    setIsLoading(false);
    e.stopPropagation();
    try {
      await addToCart({ catalogId: product._id, quantity: quantity });
      fetchProduct();
      setIsLoading(false);
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "Product Added",
          detail: "Successful",
          life: 3000,
        });
      }
    } catch (error) {
      console.log("Error updating cart", error);
      setIsLoading(false);
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Product Added",
          detail: "Successful",
          life: 3000,
        });
      }
    }
  };

  function createMarkup(html: any) {
    return { __html: html };
  }

  return (
    <>
      <Toast ref={toast}></Toast>
      <Container maxWidth={"lg"} className="product-detail-page md:py-10 py-4">
        {loading ? (
          <ProductPageShimmer />
        ) : (
          <Grid container className="product-detail justify-between">
            <Grid className="flex imageslide" item xs={6}>
              <Grid container display={"flex"}>
                <Grid
                  item
                  xs={12}
                  className="flex justify-center product-images"
                >
                  <ImageSlider imagesUrls={product?.mediaUrls} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4.5} className="product-text-wrap">
              <div
                id="info-container"
                className="flex product-detail-text flex-col gap-3"
              >
                <div id="brand-name-and-size">
                  {/* <div className="text-3xl font-bold text-gray-dark">
                    {product?.brands[0]?.name}
                  </div> */}
                  <div className="md:text-3xl text-lg font-semibold text-black capitalize">
                    {product?.name}
                  </div>
                  {/* <span className="inline-flex pb-4">Peti (850 gm)</span> */}
                </div>
                <div id="price-details" className="flex flex-col">
                  <div className="flex items-center">
                    <div className="md:text-3xl text-lg font-semibold mr-3">
                      ₹{product?.price?.selling}
                      {/* <span className="packageCount ml-2 font-semibold color-textgrey text-lg">
                        (1 X 12Pcs)
                      </span> */}
                    </div>
                    {/* <div className=" text-xl font-bold text-gray-dark line-through decoration-red decoration-[1.5px]">
                      ₹{product?.price?.mrp}
                    </div>
                    <div className="text-sm ml-3 text-secondary uppercase">
                      ({product?.percentageOff}% off)
                    </div> */}
                  </div>
                </div>
                {isLoading ? (
              <div className=" inset-0 bg-opacity-60 flex items-center justify-center z-10  h-14">
                <ProgressSpinner
                  style={{ width: "20px", height: "20px" }}
                  strokeWidth="8"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              </div>
            ) :<div
                  id="quantity-and-add-to-cart-container"
                  className="flex items-center gap-4"
                >
                  <div
                    id="quantity-counter"
                    className="flex items-center bg-white countBtn"
                  >
                    <button
                      type="button"
                      id="decrement-button"
                      onClick={(e) => handleDecrement(e)}
                      className="flex items-center justify-center bg-white"
                    >
                      <span className="text-black">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="4"
                          fill="none"
                          viewBox="0 0 20 4"
                        >
                          <path
                            fill="#343539"
                            d="M17.737 3.886H2.444c-1.008 0-1.821-.728-1.821-1.63 0-.902.813-1.63 1.82-1.63h15.294c1.008 0 1.82.728 1.82 1.63 0 .902-.812 1.63-1.82 1.63z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                    <div className="flex items-center justify-center text-black text-md font-semibold px-2">
                      {quantity}
                    </div>
                    <button
                      type="button"
                      id="increment-button"
                      onClick={(e) => handleIncrement(e)}
                      className="flex items-center justify-center bg-white"
                    >
                      <span className="text-black">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          fill="none"
                          viewBox="0 0 19 19"
                        >
                          <path
                            fill="#343539"
                            d="M17.338 7.723H11.35V1.971c0-.86-.727-1.559-1.623-1.559s-1.623.698-1.623 1.56v5.751H2.116c-.896 0-1.623.698-1.623 1.56 0 .86.727 1.559 1.623 1.559h5.988v5.752c0 .861.727 1.56 1.623 1.56s1.623-.699 1.623-1.56v-5.752h5.988c.896 0 1.623-.698 1.623-1.56 0-.86-.727-1.559-1.623-1.559z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  {product.isCartAdded ? (
                    <div id="add-to-cart-button" className="addButton">
                      <button className="flex items-center add-btn">
                        Added
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="10"
                          fill="none"
                          viewBox="0 0 14 10"
                          className="ml-2"
                        >
                          <path
                            fill="#fff"
                            d="M13.7.294a.98.98 0 0 0-1.4 0L4.8 7.647l-3.1-3.04a.98.98 0 0 0-1.4 0C-.1 5-.1 5.589.3 5.98l3.8 3.726c.2.196.4.294.7.294s.5-.098.7-.294l8.2-8.04c.4-.391.4-.98 0-1.372"
                          ></path>
                        </svg>
                        {/* <span className=' z-10 text-xl'>+</span>
                      <div className='absolute inset-0 bg-green-light opacity-40 z-0'></div> */}
                      </button>
                    </div>
                  ) : (
                    <div id="add-to-cart-button" className="addButton">
                      <button
                        disabled={isLoading}
                        className="flex items-center add-btn"
                        onClick={(e) => addProductToCart(e)}
                      >
                        Add{" "}
                        <svg
                          className="ml-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="18"
                          fill="none"
                          viewBox="0 0 19 18"
                        >
                          <path
                            fill="#fff"
                            d="M17.29 7.311h-5.987V1.56C11.303.7 10.576 0 9.68 0S8.057.698 8.057 1.56V7.31H2.069c-.896 0-1.623.698-1.623 1.56 0 .86.727 1.559 1.623 1.559h5.988v5.752c0 .861.727 1.56 1.623 1.56s1.623-.699 1.623-1.56V10.43h5.988c.896 0 1.623-.698 1.623-1.56 0-.86-.727-1.559-1.623-1.559z"
                          ></path>
                        </svg>
                        {/* <span className=' z-10 text-xl'>+</span>
                      <div className='absolute inset-0 bg-green-light opacity-40 z-0'></div> */}
                      </button>
                    </div>
                  )}
                </div>}
                <div
                  id="product-container"
                  className="mt-4 flex flex-col"
                >
                  <span className="text-lg md:text-2xl font-semibold">
                    Product Details
                  </span>
                  <div
                    className="block items-center font-medium color-text-lightgray"
                    contentEditable="true"
                    dangerouslySetInnerHTML={createMarkup(product.description)}
                  ></div>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </Container>

      {/* <div className="wrap-similaritems py-6">
        <div className="custom-container">
          <div className='md:text-3xl text-2xl font-semibold pb-4'>Similar Items</div>
          Add similar product card here...
        </div>
      </div> */}

      <WholeSaleSolution />
    </>
  );
};

export default ProductPage;
