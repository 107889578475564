// src/Login.tsx

import React, { useRef, useState } from "react";
import SignInPopup from "../../components/pop-up-components/SignInPopup";
import { ConfirmationResult } from "firebase/auth";
import ValidateOTPPopup from "../../components/pop-up-components/ValidateOTPPopup";
import SignUpPopup from "../../components/pop-up-components/SignUpPopup";

const Login: React.FC = () => {
    const stepperRef = useRef<any>(null);
    const [steps, setSteps] = useState(1);
    const [confirmationResult, setConfirmationResult]= useState<ConfirmationResult | null>(); 
    const [firebaseMetadata,setFirebaseMetadata]= useState()
    
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const bgImg = require('../../assets/login-bgImage.jpg');
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("Email:", email);
    console.log("Password:", password);
  };

  return (
    <div style = {{
        backgroundImage:
        `url(${bgImg})`
        ,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
     }} 
     className="flex items-center justify-center min-h-screen  bg-gray-100 position: relative">
      <div className="w-full max-w-md bg-white shadow-md pb-24 rounded-3xl p-6 position: absolute bottom-0">
        {steps === 1 && (
                    <div className="firststep">
                        <div className="inner-wrap">
                            <SignInPopup setSteps={setSteps} setVisibleLogin={undefined}  setConfirmationResult={setConfirmationResult}/>
                        </div>
                    </div>
                )}

                {steps === 2 && (
                    <div className="otpscreen">
                         <ValidateOTPPopup setSteps={setSteps} setVisibleLogin={undefined} confirmationResult={confirmationResult} setFirebaseMetadata={setFirebaseMetadata} />
                    </div>
                )}

                {steps === 3 && (
                    <div className="detailwrapper">
                        {/* <SignUpComponent /> */}
                        {/* SignUp Component */}
                        <SignUpPopup setSteps={setSteps} setVisibleLogin={undefined} firebaseMetadata={firebaseMetadata}/>
                    </div>
                )}

                {steps === 4 && (
                    <div className="messagewrapper">
                        <svg
                            className='m-auto'
                            xmlns="http://www.w3.org/2000/svg"
                            width="54"
                            height="54"
                            fill="none"
                            viewBox="0 0 54 54"
                        >
                            <circle cx="27" cy="27" r="27" fill="#3C881F"></circle>
                            <path
                            fill="#fff"
                            d="M40.35 17.65a2.22 2.22 0 0 0-3.141 0L23.074 31.786l-6.282-6.283a2.22 2.22 0 1 0-3.141 3.142l7.852 7.852c.434.434 1.002.65 1.57.65s1.138-.216 1.571-.65L40.35 20.792a2.22 2.22 0 0 0 0-3.142"
                            ></path>
                        </svg>
                        <p className='text-center color-text-green text-2xl font-semibold mt-2'>Successfully Logged in!</p>
                    </div>
                )}
      </div>
    </div>
  );
};

export default Login;
