import { SERVER_ENDPONTS } from '../../constant/server_endpoints';
import axiosClient, { cancelAllRequests } from '../axiosClient';

/**
 * Fetches the catalog list with optional query parameters.
 * @param {Object} params - Query parameters to filter the catalog list.
 * @returns {Promise} Axios response promise.
 */
export function getCatalogList(params = {}) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint if needed
  return axiosClient.get(SERVER_ENDPONTS.CATALOG.CATALOG_LIST, { params });
}

/**
 * Fetches product details from the catalog with optional query parameters.
 * @param {Object} params - Query parameters to fetch specific product details.
 * @returns {Promise} Axios response promise.
 */
export function getRequiredProductFromCatalog(params = {}) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint if needed
  return axiosClient.get(SERVER_ENDPONTS.CATALOG.PRODUCT_DETAILS, { params });
}

/**
 * Fetches the list of categories with optional query parameters.
 * @param {Object} params - Query parameters to fetch categories.
 * @returns {Promise} Axios response promise.
 */
export function getCategories(params = {}) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint if needed
  return axiosClient.get(SERVER_ENDPONTS.CATEGORY, { params });
}

/**
 * Fetches the list of brands with optional query parameters.
 * @param {Object} params - Query parameters to fetch brands.
 * @returns {Promise} Axios response promise.
 */
export function getBrands(params = {}) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint if needed
  return axiosClient.get(SERVER_ENDPONTS.BRAND, { params });
}
export function getConfigurations(id){
    return axiosClient.get(`${SERVER_ENDPONTS.CONFIG}/${id}`)
}
