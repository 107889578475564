import { SERVER_ENDPONTS } from "../../constant/server_endpoints";
import axiosClient, { cancelAllRequests } from "../axiosClient";

export function addProductToCart(payload = {}) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.post(SERVER_ENDPONTS.CART.ADD_TO_CART, payload);
}

export function removeProductFromCart(payload = {}) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.delete(`${SERVER_ENDPONTS.CART.REMOVE_FROM_CART}/${payload}`);
}

export function updateCart(payload = {}) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.put(SERVER_ENDPONTS.CART.UPDATE_CART, payload);
}

export function emptyCart(payload = {}) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.delete(SERVER_ENDPONTS.CART.EMPTY_CART);
}

export function getAllCart() {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.get(SERVER_ENDPONTS.CART.GET_ALL_CART);
}

// Uncomment if needed and integrate cancellation logic
// export function findInCart() {
//   cancelAllRequests(); // Cancel any ongoing requests for this endpoint
//   return axiosClient.get(SERVER_ENDPONTS.CART.FIND_IN_CART);
// }

export function checkout(payload = {}) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.post(SERVER_ENDPONTS.PAYMENT.CHECKOUT, payload);
}

export function cartCounts() {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.get(SERVER_ENDPONTS.CART.COUNT);
}

export function getOrderStatus(payload = {}) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.post(SERVER_ENDPONTS.PAYMENT.VERIFY, payload);
}

export function getOrderList() {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.get(SERVER_ENDPONTS.PAYMENT.ORDER_LIST);
}

export function getOrderById(orderID: string) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.get(`${SERVER_ENDPONTS.PAYMENT.ORDER_BY_ID}/${orderID}`);
}

export function getTransactionList() {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.get(SERVER_ENDPONTS.PAYMENT.VERIFY);
}

export function getTransactionByID(orderID: string) {
  cancelAllRequests(); // Cancel any ongoing requests for this endpoint
  return axiosClient.get(`${SERVER_ENDPONTS.PAYMENT.VERIFY}/${orderID}`);
}
