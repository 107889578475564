import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getOrderList } from "../../api/lib/cart";
import OrderCard from "../../components/order-card/OrderCard";

const OrderPage = () => {
  const [orderList, setOrderList] = useState<any>([]);

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    try {
      const response = await getOrderList();
      console.log('getOrders', response);
      setOrderList(response?.data?.data || []);
    } catch (error) {
      console.log("Failed to get order List", error);
    }
  };

  return (
    <>
      <Container className="order-container py-6 min-h-[60vh]" maxWidth="lg">
        <div className="titleWrapper flex justify-between">
          <p className="md:text-3xl text-lg color-lightblack font-bold md:mt-4">
            My Orders
          </p>
          {/* <div className="fliter-dropdown">
            {/* <button
              data-popover-target="menu"
              className="btnfilter rounded-xl md:py-2 mb-1 px-4 bg-[#CD1318] border border-transparent md:text-lg text-[10px] flex items-center justify-center md:min-w-[148px] text-white transition-all"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                className="md:mr-2 mr-1"
              >
                <path
                  fill="#fff"
                  d="M.75 4.238h11.419a3.5 3.5 0 0 0 3.412 2.74 3.5 3.5 0 0 0 3.41-2.74h4.259a.75.75 0 1 0 0-1.498h-4.259A3.5 3.5 0 0 0 15.581 0a3.5 3.5 0 0 0-3.412 2.74H.75a.75.75 0 1 0 0 1.498m12.838-.747v-.008a1.995 1.995 0 0 1 1.993-1.984c1.093 0 1.987.889 1.992 1.982v.011a1.994 1.994 0 0 1-1.992 1.988c-1.098 0-1.99-.89-1.993-1.986zm9.662 16.27h-4.259a3.5 3.5 0 0 0-3.41-2.74 3.5 3.5 0 0 0-3.412 2.74H.75a.75.75 0 1 0 0 1.5h11.419A3.5 3.5 0 0 0 15.581 24a3.5 3.5 0 0 0 3.41-2.74h4.259a.75.75 0 1 0 0-1.498m-7.67 2.74c-1.097 0-1.99-.89-1.992-1.986v-.01a1.995 1.995 0 0 1 1.993-1.985c1.093 0 1.987.89 1.992 1.983v.01a1.994 1.994 0 0 1-1.992 1.988m7.67-11.25H11.831A3.5 3.5 0 0 0 8.42 8.51a3.5 3.5 0 0 0-3.412 2.74H.75a.75.75 0 1 0 0 1.498h4.258a3.5 3.5 0 0 0 3.412 2.74 3.5 3.5 0 0 0 3.41-2.74h11.42a.75.75 0 1 0 0-1.498m-12.838.747v.008a1.995 1.995 0 0 1-1.992 1.985 1.996 1.996 0 0 1-1.993-1.983v-.011a1.994 1.994 0 0 1 3.985-.002z"
                ></path>
              </svg>
              Filter
            </button>
            <ul
              role="menu"
              data-popover="menu"
              data-popover-placement="bottom"
              className="filtermenu absolute z-10 min-w-[148px] py-2 overflow-auto rounded-xl bg-white border border-[#EFEFF1] color-lightblack focus:outline-none transition-opacity duration-300 opacity-0"
            >
              <li role="menuitem" className="filter-menuitem">
                All
              </li>
              <li role="menuitem" className="filter-menuitem">
                Delivered
              </li>
              <li role="menuitem" className="filter-menuitem">
                Cancelled
              </li>
              <li role="menuitem" className="filter-menuitem">
                Returned
              </li>
            </ul>
          </div> */}
        </div>
        <div className="order-card-wrap">
          {orderList?.length ? (
            <div className="order-list-wrap flex flex-wrap gap-4">
              {orderList?.map((order, index) => <OrderCard order={order} />)}
            </div>
          ) : (
            <div className="empity-order flex flex-col items-center my-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="161"
                height="151"
                fill="none"
                viewBox="0 0 161 151"
              >
                <path
                  fill="#F2F2F2"
                  d="M134.167 26.833 67.083 53.667 0 26.833 67.083 0z"
                ></path>
                <path
                  fill="#BFBFBF"
                  d="M67.083 53.666V128.8L0 101.966V26.833z"
                ></path>
                <path
                  fill="#8C8C8C"
                  d="M134.167 26.833v75.133L67.083 128.8V53.666z"
                ></path>
                <path
                  fill="#595959"
                  d="M111.036 17.576 46.261 45.35l-.644.268v24.15l-18.784-8.05v-24.15L91.61 9.821z"
                ></path>
                <path
                  fill="#F2F2F2"
                  d="M123.433 150.267c20.748 0 37.567-16.819 37.567-37.567 0-20.747-16.819-37.566-37.567-37.566-20.747 0-37.566 16.819-37.566 37.566s16.819 37.567 37.566 37.567"
                ></path>
                <path
                  fill="#595959"
                  d="M138.755 97.379a2.67 2.67 0 0 0-3.783 0L123.46 108.89l-11.511-11.511a2.673 2.673 0 0 0-3.784 0 2.673 2.673 0 0 0 0 3.783l11.512 11.512-11.512 11.511a2.674 2.674 0 0 0 0 3.784 2.64 2.64 0 0 0 1.906.778c.697 0 1.368-.269 1.905-.778l11.511-11.512 11.512 11.512a2.64 2.64 0 0 0 1.905.778c.698 0 1.368-.269 1.905-.778a2.67 2.67 0 0 0 0-3.784l-11.511-11.511 11.511-11.512a2.67 2.67 0 0 0 0-3.783z"
                ></path>
              </svg>
              <p className="description italic olor-lightblack font-medium md:text-4xl text-lg">
                There are no orders!
              </p>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default OrderPage;
