import { Paper, Grid, Box, Typography, Divider, Modal } from "@mui/material";
import { useWishlist } from "../../context/WishlistContext";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import testImage from '../../assets/testImage.jpg';
import { useState } from "react";
// import { addToCart, removeFromCart } from "../../api/lib/cart";

const MAX_TITLE_LENGTH = 27;

const WishlistCard = ({ product }) => {
  const { removeFromWishlist } = useWishlist();
  const { addToCart } = useCart();
  // const [selectedSize, setSelectedSize] = useState("XS");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  // const findCartItem = cartItems.find((cart_item) => cart_item._id === product._id);

  // const handleOpen = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setOpen(true);
  // };

  const handleClose = () => setOpen(false);

  // const handleSizeConfirm = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   addToCart(product, "01", "L");
  //   handleClose();
  // };

  const handleCartClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(product)
    // const payload = {
    //   catalogId: product?._id,
    //   quantity: 1,
    //   price: 999,
    //   reqType: 1
    // };
    console.log(product._id); 
    addToCart({ catalogId: product?._id, quantity: 1, price: 999, reqType: 1 })
      .then((response) => {
        console.log(response);
      }).catch((error) => {
        console.error(error);
      })
    // if (findCartItem) {
    //   removeCartItem(product._id);
    // } else {
    //   // handleOpen(e);
    // }
  };

  const handleCardClick = () => {
    navigate(`/product/${product.catalogId}`);
  };

  const handleImageError = (e) => {
    e.target.src = testImage;
  };

  return (
    <Grid item xs={3}>
      <Paper className="cursor-pointer" elevation={1} onClick={handleCardClick}>
        <Box className="flex justify-end mr-1">
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeFromWishlist(product.catalogId);
            }}
            className="mt-1 absolute hover:text-primary"
          >
            <CancelOutlinedIcon fontSize="medium" />
          </button>
        </Box>
        {product?.imagesUrls && product.imagesUrls.length > 0 && (
          <img
            className="product-image"
            src={product.imagesUrls[0]}
            alt={product.name}
            onError={handleImageError}
          />
        )}
        <Box marginLeft={1}>
          <Box sx={{ paddingTop: 0.5, paddingBottom: 0.25 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              {product?.name?.length > MAX_TITLE_LENGTH
                ? `${product?.name.substring(0, MAX_TITLE_LENGTH)}...`
                : product?.name}
            </Typography>
          </Box>
          <Divider />
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 400, color: "#737577" }}
            >
              {product.categoryId}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }} marginTop={0.5}>
            <Typography fontWeight={"Bold"} variant="body2">
              ₹ {product?.price?.mrp}
            </Typography>
            <Typography color={"#888888"} marginLeft={1} variant="body2">
              MRP inclusive of all taxes
            </Typography>
          </Box>
        </Box>
        <Box className="block mt-4" display={"flex"} justifyContent={"center"}>
          <button
            className="w-full py-2 text-secondary capitalize text-sm font-bold border border-solid border-2 border-gray flex items-center justify-center"
            onClick={handleCartClick}
          >
            Add To Cart
            {/* {findCartItem ? "Added to Cart" : "Add to Cart"}
            {findCartItem && <CheckCircleIcon className="ml-1" fontSize="small" />} */}
          </button>
        </Box>
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[540px] bg-white shadow-xl rounded-md">
          <div className="px-3 flex flex-col pt-3 pb-2">
            <div className="flex justify-center pb-3">
              <div className="w-[40px] h-[2px] bg-black rounded-full"></div>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-sm font-bold capitalize tracking-wider">Select your preferred size</span>
              <button onClick={handleClose}><CloseIcon fontSize="medium" className="cursor-pointer hover:text-primary" /></button>
            </div>
          </div>
          <Divider className="mt-1" sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
          {/* <div className="pt-4 px-3">
            <div className="flex">
              {product.sizeAvailable.map((size, index) => (
                <button
                  onClick={() => setSelectedSize(size)}
                  className={`mr-3 w-12 py-2 text-xs font-bold flex justify-center items-center rounded-md ${selectedSize === size ? 'bg-primary text-white border-solid border-2 border-primary' : 'bg-white text-gray-800 border-solid border-2 border-secondary'}`}
                  key={index}
                >
                  {size}
                </button>
              ))}
            </div> */}
          {/* <Box className="mt-3 mb-3 py-2.5 flex justify-center text-white bg-secondary cursor-pointer rounded-md" onClick={handleSizeConfirm}>
              <button className="text-sm uppercase tracking-wider">Done</button>
            </Box>
          </div>
        // </div> */}
          /</div>
      </Modal>
    </Grid>
  );
};

export default WishlistCard;
