import { Dialog } from "primereact/dialog";
import React, { useRef, useState } from "react";
import { ConfirmationResult } from "firebase/auth";
import SignInPopup from "./SignInPopup";
import ValidateOTPPopup from "./ValidateOTPPopup";
import SignUpPopup from "./SignUpPopup";
import LocationPopup from "./locationPopup";
import { useAuth } from "../../context/AuthContext";

interface PopupOutletProps {
  visible: boolean;
  onHide: () => void;
  setVisibleLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopupOutlet: React.FC<PopupOutletProps> = ({
  visible,
  onHide,
  setVisibleLogin,
}) => {

  const {steps, setSteps} = useAuth(); 
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult | null>();
  const [firebaseMetadata, setFirebaseMetadata] = useState();

  const handleHide = () => {
    if (steps === 2) {
      setSteps(1); 
    }
    setVisibleLogin(false);
    onHide();
  };

  return (
    <Dialog
      draggable={false}
      visible={visible}
      blockScroll={true}
      style={{ width: "35vw" }}
      onHide={handleHide}
      className="bg-white p-popup"
    >
      <div className="mainWrapper">
        {steps === 1 && (
          <div className="firststep">
            <div className="inner-wrap">
              <SignInPopup
                setSteps={setSteps}
                setVisibleLogin={setVisibleLogin}
                setConfirmationResult={setConfirmationResult}
              />
            </div>
          </div>
        )}

        {steps === 2 && (
          <div className="otpscreen">
            <ValidateOTPPopup
              setSteps={setSteps}
              setVisibleLogin={setVisibleLogin}
              confirmationResult={confirmationResult}
              setFirebaseMetadata={setFirebaseMetadata}
            />
          </div>
        )}

        {steps === 3 && (
          <div className="detailwrapper">
            <SignUpPopup
              setSteps={setSteps}
              setVisibleLogin={setVisibleLogin}
              firebaseMetadata={firebaseMetadata}
            />
          </div>
        )}

        {steps === 4 && (
          <div className="detailwrapper">
            <LocationPopup
              setSteps={setSteps}
              setVisibleLogin={setVisibleLogin}
              // onAddressSelect={handleAddressSelect}
              // initialAddress={selectedAddress}
            />
          </div>
        )}

        {steps === 5 && (
          <div className="messagewrapper">
            <svg
              className="m-auto"
              xmlns="http://www.w3.org/2000/svg"
              width="54"
              height="54"
              fill="none"
              viewBox="0 0 54 54"
            >
              <circle cx="27" cy="27" r="27" fill="#3C881F"></circle>
              <path
                fill="#fff"
                d="M40.35 17.65a2.22 2.22 0 0 0-3.141 0L23.074 31.786l-6.282-6.283a2.22 2.22 0 1 0-3.141 3.142l7.852 7.852c.434.434 1.002.65 1.57.65s1.138-.216 1.571-.65L40.35 20.792a2.22 2.22 0 0 0 0-3.142"
              ></path>
            </svg>
            <p className="text-center color-text-green text-2xl font-semibold mt-2">
              Successfully Logged in!
            </p>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default PopupOutlet;
