import { useState } from "react";
import testImage from "../../assets/icons/pushkar_foods_thumbnail.png";
import constant from "../../constant/constants";
import { ENUM } from "../../constant/enum";

const OrderCard = ({order}) => {
  const product1: any = order.items[0];
  console.log('product1', product1)
  const baseURL = constant.imageURI;
  const [value, setValue] = useState(null);

  const getStatusName = (code) => {
    switch (code) {
      case ENUM.OFFER_STATUS.PROCESSING:
        return "Processing";
      case ENUM.OFFER_STATUS.SUCCESS:
        return "Success";
      case ENUM.OFFER_STATUS.FAILED:
        return "Failed";
      default:
        return "Unknown Status";
    }
  };


  const formatDate = (dateString: string | undefined | null): string => {
    if (!dateString) {
      console.error("Invalid dateString:", dateString);
      return "Invalid Date";
    }
  
    const date = new Date(dateString);
  
    if (isNaN(date.getTime())) {
      console.error("Invalid Date object created:", dateString);
      return "Invalid Date";
    }
  
    const options: Intl.DateTimeFormatOptions = { 
      day: "numeric", 
      month: "short", 
      year: "numeric" 
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  

  return (
    <>
      <div className="bg-[#FFFFFF] border rounded-3xl border-[#D2D2D2] p-4 md:w-[49%] w-full">
        <div className="order-card flex justify-between w-full border-b border-[#EFEFF1] md:pb-4 md:mb-4 pb-2 mb-2">
          {/* {order?.order?.items?.map((item) => ( */}
          <div className="flex items-start justify-start gap-2">
            <div className="flex justify-center md:h-[95px] h-[40px] md:w-[65px] w-[30px] border rounded-xl border-[#EFEFF1]">
              <img className="w-auto h-auto" src={product1?.mediaUrls && product1?.mediaUrls?.length > 0
                ? product1.mediaUrls[0].resizedUrls?.[2]?.url
                : testImage} />
            </div>
            <div className="ml-2">
              <div className="font-semibold color-lightblack capitalize md:text-xl text-xs">
                {product1.name}
              </div>
              {/* <div className="md:text-lg font-medium md:mt-2 mt-1 text-[8px]">
                  870G (10 pcs)
                </div> */}
            </div>
          </div>
          {/* ))} */}
          <div className="delivery-status text-right">
            <div className="status md:text-base text-[8px] color-lightblack font-semibold flex items-center">
              <span className="dot dot-Deliverd dot-Cancelled dot-Return"></span>{" "}
              {getStatusName(order?.orderStatus)}
            </div>
            <div className="date color-lightblack font-medium md:text-sm text-[8px] md:mt-4 mt-1">
              On, {formatDate(order?.createdAt)}

            </div>
          </div>
        </div>
        {/* <div className="color-lightblack font-semibold md:text-sm text-[10px]">
          Exchange/Return window closed on 30 Sep, 2024
        </div> */}
        {/* <div className="rating md:mt-4 mt-2">
          <Rating value={value} onChange={(e) => setValue(e.value)} stars={5} />
          <p className="color-lightblack font-semibold md:text-sm mt-2 text-[8px] ratingtext">Rate and Review</p>
        </div> */}
      </div>
    </>
  );
};

export default OrderCard;
