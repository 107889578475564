import {
  Container,
  Box,
  Divider,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { getUserAddressList } from "../../../api/lib/user";
import LocationPopup from "../../../components/pop-up-components/locationPopup";
import { useAuth } from "../../../context/AuthContext";

const EditProfile = () => {
  const { currentUser } = useAuth();
  const [visible, setVisible] = useState(false);
  const [address, setAddress] = useState([]);

  useEffect(() => {
    getAddressList();
  }, []);

  const handleHide = () => {
    setVisible(false);
    getAddressList();
  };

  const showAddressPopup = () => {
    setVisible(true);
  };

  const handleSave = () => {
    // Handle save logic here
  };

  const getAddressList = async () => {
    try {
      if (currentUser) {
        const response = await getUserAddressList();
        if (response.data?.data[0]) {
          console.log("getUserAddressList", response.data?.data);
          setAddress(response.data?.data); // Set the first address or a fallback
        }
      } else {
        // setAddress("Location");
      }
    } catch (error) {
      console.error("Error fetching address list", error);
    }
  };

  return (
    <>
      {/* <Grid item xs={6} >
                <Box marginBottom={2}>
                    <div className="uppercase text-sm text-gray-dark">Edit Profile</div>
                </Box>
                <div className="pl-4 pt-3 pb-5 border-2 border-gray">
                    <div className="text-sm">Email Id</div>
                    <div className="bg-gray-light h-9 px-2 py-[4px] items-center w-[400px] mt-1 rounded-sm">
                        {currentUser?.email}
                    </div>
                </div>
                <Box marginTop={3} paddingBottom={5} border="1px solid black">
                    <Typography variant="body2" sx={{ marginTop: 3, marginBottom: 3, marginLeft: 2.5 }}>
                        General Information
                    </Typography>

                    <Divider />
                    <Grid display="flex" justifyContent="space-around" container>
                        <Grid display="flex" item flexDirection="column" justifyContent="space-between" xs={5.3}>
                            <div className="border-2 my-2 outline-none">
                                <input type="text" name="firstname" id="firstName" placeholder="First Name*" className="h-full w-full pl-2 py-2 bg-gray-light" value={firstName} readOnly />
                            </div>
                            <div className="border-2 my-2 outline-none">
                                <input type="text" name="lastname" id="lastName" placeholder="Last Name*" className="h-full w-full pl-2 py-2 bg-gray-light" value={lastName} readOnly />
                            </div>
                            <div className="my-2">
                                <div className="text-sm">Gender</div>
                                <div className="flex justify-start items-center outline-none">
                                    <div className="flex items-center mr-3">
                                        <input type="radio" name="gender-radio" className="w-4 h-4 text-blue-dark bg-gray-light border-gray-light accent-primary" value="Male" checked={gender === "Male"} onChange={(e) => setGender(e.target.value)} />
                                        <label className="ms-2 text-sm font-medium text-gray-dark">Male</label>
                                    </div>
                                    <div className="flex items-center mr-3">
                                        <input type="radio" name="gender-radio" className="w-4 h-4 text-blue-dark bg-gray-light border-gray-light accent-primary" value="Female" checked={gender === "Female"} onChange={(e) => setGender(e.target.value)} />
                                        <label className="ms-2 text-sm font-medium text-gray-dark">Female</label>
                                    </div>
                                    <div className="flex items-center mr-3">
                                        <input type="radio" name="gender-radio" className="w-4 h-4 text-blue-dark bg-gray-light border-gray-light accent-primary" value="Others" checked={gender === "Others"} onChange={(e) => setGender(e.target.value)} />
                                        <label className="ms-2 text-sm font-medium text-gray-dark">Others</label>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Grid display="flex" flexDirection="column" justifyContent="space-between" item xs={5.3}>
                            <div className="border-2 px-2 py-2 my-2 flex">
                                <input type="date" name="birthdate" id="birthdate" className="w-full outline-none" value={dob} onChange={(e) => setDob(e.target.value)} />
                            </div>
                            <div className={`border-2 my-2 outline-none`}>
                                <input type="text" name="phone" id="phoneNumber" placeholder="Phone Number*" className="w-full h-full pl-2 py-2" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                            </div>
                            {phoneNumberErr && <small className="text-sm text-red">{phoneNumberErr}</small>}
                            <div className="border-2 pl-2 py-2 my-2">
                                <input type="text" name="address" id="address" placeholder="Address*" className="w-full outline-none" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                        </Grid>
                        <Divider />
                        <Box display="flex" justifyContent="center" marginTop="20px">
                            <Button disableRipple variant="contained" color="primary" onClick={handleSave} style={{ backgroundColor: "rgb(16,123,122)", width: "200px" }}>
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </Grid> */}
      <Container maxWidth="lg">
        <div className="titleWrapper flex justify-between mt-8">
          <p className="md:text-3xl text-lg color-lightblack font-bold">
            Edit Profile
          </p>
          <button
            data-popover-target="menu"
            className="btn-address md:rounded-xl rounded-[5px] py-2 mb-1 px-4 bg-[#CD1318] border border-transparent md:text-lg text-[10px] hidden md:flex items-center justify-center min-w-[148px] text-white transition-all hover:bg-transparent hover:border-[#CD1318] hover:text-[#CD1318]"
            type="button"
            onClick={showAddressPopup}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="23"
              fill="none"
              viewBox="0 0 22 23"
              className="mr-2"
            >
              <path
                fill="#fff"
                d="M11 .5a11 11 0 1 0 11 11 11.013 11.013 0 0 0-11-11m5 12h-4v4a1 1 0 0 1-2 0v-4H6a1 1 0 0 1 0-2h4v-4a1 1 0 0 1 2 0v4h4a1 1 0 0 1 0 2"
              ></path>
            </svg>
            Add New Address
          </button>
        </div>
        <div className="profile-wrapper mt-4 pb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="col-left">
            <div className="profile-info-card mb-6 md:max-w-[80%] bg-white border border-[#EFEFF1] rounded-3xl shadow-[0px_0px_20px_6px_#00000017;] py-4 px-6">
              <button className="text-[#CD1318] editbtn font-semibold flex items-center justify-self-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  fill="none"
                  viewBox="0 0 15 15"
                  className="mr-1"
                >
                  <path
                    fill="#CD1318"
                    d="M11.196 7.928a.59.59 0 0 0-.589.59v4.714a.59.59 0 0 1-.59.589h-8.25a.59.59 0 0 1-.588-.59v-8.25a.59.59 0 0 1 .589-.589h4.714a.59.59 0 1 0 0-1.178H1.768A1.77 1.77 0 0 0 0 4.982v8.25A1.77 1.77 0 0 0 1.768 15h8.25a1.77 1.77 0 0 0 1.768-1.768V8.517a.59.59 0 0 0-.59-.589"
                  ></path>
                  <path
                    fill="#CD1318"
                    d="M5.877 6.967a.3.3 0 0 0-.083.155l-.431 2.156a.306.306 0 0 0 .359.359l2.155-.431a.3.3 0 0 0 .156-.084l4.824-4.824-2.155-2.155zM14.584.416a1.423 1.423 0 0 0-2.011 0l-.787.787 2.01 2.011.788-.787A1.4 1.4 0 0 0 15 1.422c0-.38-.148-.738-.416-1.006"
                  ></path>
                </svg>
                Edit
              </button>
              <div className="field-wrapper">
                <div className="field-group">
                  <label>Full Name</label>
                  <div id="name" className="name field-group-value">
                    {currentUser?.fullName || ""}
                  </div>
                </div>
                <div className="field-group">
                  <label>Mobile Number</label>
                  <div id="mnumber" className="mobile-number field-group-value">
                    {currentUser?.phone || ""}
                  </div>
                </div>
                <div className="field-group">
                  <label>Email ID</label>
                  <div id="email" className="email field-group-value">
                    {currentUser?.email || ""}
                  </div>
                </div>
                <div className="field-group">
                  <label>Legal Entity Name</label>
                  <div id="Ename" className="entityname field-group-value">
                    Guest Account
                  </div>
                </div>
                <div className="field-group">
                  <label>PAN Card Number</label>
                  <div id="pan" className="panumber text-[#CD1318]">
                    Unverified
                  </div>
                </div>
              </div>
            </div>
            <div className="password-card md:max-w-[80%] bg-white border border-[#EFEFF1] rounded-3xl py-4 px-6">
              <div className="field-group">
                <label>Current Password</label>
                <input
                  type="password"
                  className="password border border-[#EFEFF1] text-[#9596A4] rounded-xl p-3 w-full 
                            disabled:border-white disabled:bg-white disabled:text-4xl"
                  id="password"
                  value="Password"
                  disabled
                />
              </div>
              <button className="btn-update bg-[#3C881F] md:h-[63px] md:min-w-[197px] min-w-[120px] p-2 text-white border border-transparent md:text-base text-[10px] text-center font-semibold rounded-[32px] hover:bg-white hover:border-[#3C881F] hover:text-[#3C881F]">
                Change Password
              </button>
            </div>
          </div>
          <div className="col-right">
            {address?.map((address) => (
              <div className="address-card bg-white border border-[#EFEFF1] rounded-3xl py-4 px-6 mb-4">
                <div className="card-top flex justify-between items-center border-b border-[#EFEFF1] pb-3 mb-4">
                  <div className="title font-semibold color-lightblack md:text-base text-sm">
                    Default Address
                  </div>
                  <button className="editbtn text-[#CD1318] font-semibold flex items-center justify-self-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      fill="none"
                      viewBox="0 0 15 15"
                      className="mr-1"
                    >
                      <path
                        fill="#CD1318"
                        d="M11.196 7.928a.59.59 0 0 0-.589.59v4.714a.59.59 0 0 1-.59.589h-8.25a.59.59 0 0 1-.588-.59v-8.25a.59.59 0 0 1 .589-.589h4.714a.59.59 0 1 0 0-1.178H1.768A1.77 1.77 0 0 0 0 4.982v8.25A1.77 1.77 0 0 0 1.768 15h8.25a1.77 1.77 0 0 0 1.768-1.768V8.517a.59.59 0 0 0-.59-.589"
                      ></path>
                      <path
                        fill="#CD1318"
                        d="M5.877 6.967a.3.3 0 0 0-.083.155l-.431 2.156a.306.306 0 0 0 .359.359l2.155-.431a.3.3 0 0 0 .156-.084l4.824-4.824-2.155-2.155zM14.584.416a1.423 1.423 0 0 0-2.011 0l-.787.787 2.01 2.011.788-.787A1.4 1.4 0 0 0 15 1.422c0-.38-.148-.738-.416-1.006"
                      ></path>
                    </svg>
                    Edit
                  </button>
                </div>
                <div className="address-detail">
                  <p className="text-[#6E6F72]">
                    {address?.addressFirstLine}
                    <br />
                    Pin - {address?.pincode}
                    <br />
                  </p>
                  <p className="color-lightblack font-semibold">
                    {address?.fullName} <br /> Mobile: {address?.mobileNumber}
                  </p>
                </div>
              </div>
            ))}

            {/* <div className="address-card bg-white border border-[#EFEFF1] rounded-3xl py-4 px-6 mb-4">
              <div className="card-top flex justify-between items-center border-b border-[#EFEFF1] pb-3 mb-4">
                <div className="title font-semibold color-lightblack md:text-base text-sm">
                  Other Address
                </div>
                <button className="editbtn text-[#CD1318] font-semibold flex items-center justify-self-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="none"
                    viewBox="0 0 15 15"
                    className="mr-1"
                  >
                    <path
                      fill="#CD1318"
                      d="M11.196 7.928a.59.59 0 0 0-.589.59v4.714a.59.59 0 0 1-.59.589h-8.25a.59.59 0 0 1-.588-.59v-8.25a.59.59 0 0 1 .589-.589h4.714a.59.59 0 1 0 0-1.178H1.768A1.77 1.77 0 0 0 0 4.982v8.25A1.77 1.77 0 0 0 1.768 15h8.25a1.77 1.77 0 0 0 1.768-1.768V8.517a.59.59 0 0 0-.59-.589"
                    ></path>
                    <path
                      fill="#CD1318"
                      d="M5.877 6.967a.3.3 0 0 0-.083.155l-.431 2.156a.306.306 0 0 0 .359.359l2.155-.431a.3.3 0 0 0 .156-.084l4.824-4.824-2.155-2.155zM14.584.416a1.423 1.423 0 0 0-2.011 0l-.787.787 2.01 2.011.788-.787A1.4 1.4 0 0 0 15 1.422c0-.38-.148-.738-.416-1.006"
                    ></path>
                  </svg>
                  Edit
                </button>
              </div>
              <div className="address-detail">
                <p className="text-[#6E6F72]">
                  House no. 465 Arjun nagar gali no. 2, Behind Tirath Ram
                  Hospital Arjun Nagar
                  <br />
                  Gurugram - 122001
                  <br />
                  Haryana
                </p>
                <p className="color-lightblack font-semibold">
                  Prateeksha <br /> Mobile: 7042373798
                </p>
              </div>
            </div> */}

            <button
              data-popover-target="menu"
              className="btn-address md:rounded-xl rounded-[5px] py-2 mb-1 px-4 bg-[#CD1318] border border-transparent md:text-lg text-[10px] md:hidden flex items-center justify-center min-w-[148px] text-white transition-all hover:bg-transparent hover:border-[#CD1318] hover:text-[#CD1318]"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="23"
                fill="none"
                viewBox="0 0 22 23"
                className="mr-2"
              >
                <path
                  fill="#fff"
                  d="M11 .5a11 11 0 1 0 11 11 11.013 11.013 0 0 0-11-11m5 12h-4v4a1 1 0 0 1-2 0v-4H6a1 1 0 0 1 0-2h4v-4a1 1 0 0 1 2 0v4h4a1 1 0 0 1 0 2"
                ></path>
              </svg>
              Add New Address
            </button>
          </div>
        </div>
      </Container>
      <Dialog
        draggable={false}
        visible={visible}
        blockScroll={true}
        style={{ width: "35vw" }}
        onHide={handleHide}
        className="bg-white p-popup"
      >
        <LocationPopup isSignUp={false}/>
      </Dialog>
    </>
  );
};

export default EditProfile;
