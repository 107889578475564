import React from 'react'
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';

const Breadcrumb = ({items, home}) => {

  return (
    <div>
        <BreadCrumb model={items} home={home}  className='bg-transparent text-lg pl-0'/>
    </div>
  )
}

export default Breadcrumb
