import { SERVER_ENDPONTS } from "../../constant/server_endpoints";
import axiosClient, { cancelAllRequests } from "../axiosClient";

export function userChangePassword(payload = {}) {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  return axiosClient.put(SERVER_ENDPONTS.AUTH.CHANGE_PASSWORD, payload);
}

export function userSignup(payload = {}) {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  payload["guestId"] = localStorage.getItem("guestId");
  return axiosClient.post(SERVER_ENDPONTS.AUTH.SIGN_UP, payload);
}

export function userLogin(payload = {}) {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  payload["guestId"] = localStorage.getItem("guestId");
  return axiosClient.post(SERVER_ENDPONTS.AUTH.LOGIN, payload);
}

export function userForgotPassword(payload = {}) {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  return axiosClient.post(SERVER_ENDPONTS.AUTH.FORGOT_PASSWORD, payload);
}

export function verifyUserEmail(payload = {}) {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  return axiosClient.post(SERVER_ENDPONTS.AUTH.VERIFY_EMAIL, payload);
}

export function userResendOtp(payload = {}) {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  return axiosClient.post(SERVER_ENDPONTS.AUTH.RESEND_OTP, payload);
}

export function guestUserLogin(payload = {}) {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  return axiosClient.post(SERVER_ENDPONTS.AUTH.GUEST, payload);
}

export function checkPincodeAvailability(pincode: string) {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  return axiosClient.get(`${SERVER_ENDPONTS.AUTH.PINCODE_CHECK}/${pincode}`);
}

export function updateToStep(payload = {}, step: string) {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  return axiosClient.put(`${SERVER_ENDPONTS.AUTH.UPDATE_STEP}/${step}`, payload);
}

export function getUserAddressList() {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  return axiosClient.get(SERVER_ENDPONTS.AUTH.ADDRESS_LIST);
}

export function getUserDetails() {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  return axiosClient.get(SERVER_ENDPONTS.AUTH.DETAILS);
}

export function addUserAddress(payload = {}) {
  cancelAllRequests(); // Cancel ongoing requests for this endpoint
  return axiosClient.post(SERVER_ENDPONTS.AUTH.ADDRESS_ADD, payload);
}
