import { Icon } from "@iconify/react";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getBrands } from "../../api/lib/product";
import SearchBar from "../search-bar/SearchBar";
import { Brand } from "../../interfaces/brand.interfaces";
import { ENUM, SORTING } from "../../constant/enum";

const SortingBar = ({ onSortChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeSortingOption, setActiveSortingOption] = useState<any>({
    value: searchParams.get("sort"),
    order: searchParams.get("order"),
  });
  const [visibleBrands, setVisibleBrands] = useState(false);
  const [visibleSortingOptions, setVisibleSortingOptions] = useState(false);
  const [brands, setBrands] = useState<Brand[]>([]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await getBrands({ page: 1, limit: 10 });
        const brandList = response.data.data.list;
        setBrands(brandList);
      } catch (error) {
        console.error("Error Fetching Brands", error);
      }
    };
    fetchBrands();
  }, []);

  const toggleBrandFilter = (brandId) => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    const existingBrands = updatedSearchParams.get("brand")?.split(",") || [];
    const updatedBrands = existingBrands.includes(brandId)
      ? existingBrands.filter((id) => id !== brandId)
      : [...existingBrands, brandId];

    if (updatedBrands.length > 0) {
      updatedSearchParams.set("brand", updatedBrands.join(","));
    } else {
      updatedSearchParams.delete("brand");
    }

    setSearchParams(updatedSearchParams);
  };

  const handleSortOptionClick = (optionValue, sortOrder) => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("sort", optionValue);
    updatedSearchParams.set("order", sortOrder);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
    setActiveSortingOption({ value: optionValue, order: sortOrder });
    onSortChange({ field: optionValue, order: sortOrder });
    setVisibleSortingOptions(false);
  };

  const sortingOptions = [
    {
      label: "Low Price To High Price",
      value: ENUM.SORT_BY.PRICE,
      order: SORTING.ASCENDING
    },
    {
      label: "High Price To Low Price",
      value: ENUM.SORT_BY.PRICE,
      order: SORTING.DESCENDING
    },
    {
      label: "New Launch",
      value: ENUM.SORT_BY.CREATED_DATE,
      order: SORTING.DESCENDING,
    },
  ];

  return (
    <div className="flex items-center justify-between gap-4 p-1 filter-button">
      <button
        className="flex items-center md:text-base text-[10px] justify-between font-semibold border border-[#DEDEE3] px-4 py-1 md:rounded-xl rounded md:w-[140px] md:h-[47px] w-[75px] h-[22px]"
        onClick={() => setVisibleBrands(true)}
      >
        Brand <Icon className="text-2xl" icon="iconamoon:arrow-down-2" />
      </button>
      <button
        className="flex items-center justify-between md:text-base text-[10px] font-semibold border border-[#DEDEE3] px-4 py-1 md:rounded-xl rounded md:w-[140px] md:h-[47px] w-[80px] h-[22px]"
        onClick={() => setVisibleSortingOptions(true)}
      >
        Sort By <Icon className="text-2xl" icon="iconamoon:arrow-down-2" />
      </button>

      {/* Brand Dialog */}
      <Dialog
        draggable={false}
        header="Select Brand"
        visible={visibleBrands}
        onHide={() => setVisibleBrands(false)}
        className="p-popup brand-popup md:w-[35vw]"
      >
        <div className="brand-list">
          <div className="brand-list-item rounded-xl bg-white py-2">
            {brands.map((brand) => (
              <div
                key={brand.code}
                className="brand-item flex justify-between items-center cursor-pointer px-3 py-2 mb-2"
              >
                <label className="ms-2 text-sm font-medium text-gray-dark cursor-pointer">
                  {brand.name}
                </label>
                <input
                  type="checkbox"
                  checked={
                    searchParams
                      .get("brand")
                      ?.split(",")
                      .includes(brand.code) || false
                  }
                  onChange={() => toggleBrandFilter(brand.code)}
                  className="input-checkbox"
                />
              </div>
            ))}
          </div>
        </div>
      </Dialog>

      {/* Sorting Dialog */}
      <Dialog
        draggable={false}
        header="Sort By"
        className="md:w-[35vw] p-popup brand-popup"
        visible={visibleSortingOptions}
        onHide={() => setVisibleSortingOptions(false)}
      >
        {/* <div className='sortoption'>
          {sortingOptions.map((option) => (
            <div
              key={option.value}
              onClick={() => handleSortOptionClick(option.value, option.order)}
              className={`flex justify-between items-center cursor-pointer p-4 option-item bg-white text-lightblack rounded-xl mb-2 ${activeSortingOption === option.label ? "bg-[#3C881F] text-white" : "hover:bg-[#3C881F] hover:text-white"
                }`}
            >
              <label className="ms-2 text-xl font-medium">
                {option.label}
              </label>
            </div>
          ))}
        </div> */}
        <div className="sortoption">
          {sortingOptions.map((option) => (
            <div
              key={option.value + option.order}
              onClick={() => handleSortOptionClick(option.value, option.order)}
              className={`flex justify-between items-center cursor-pointer p-4 option-item  text-lightblack rounded-xl mb-2 ${
                activeSortingOption.value == option.value &&
                activeSortingOption.order == option.order
                  ? "bg-[#3C881F] text-white"
                  : "bg-white hover:bg-[#3C881F] hover:text-white"
              }`}
            >
              <label className="ms-2 text-xl font-medium">{option.label}</label>
            </div>
          ))}
        </div>
      </Dialog>
    </div>
  );
};

export default SortingBar;
