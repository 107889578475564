import React from 'react'
import { Container } from '@mui/material'

const ContactUs = () => {
  return (
    <Container maxWidth={"lg"} className='contactus staticpage'>
      <h6 className='md:text-4xl text-lg font-semibold color-textred md:mt-10 py-4'>Contact Us</h6>
      <div className="wrap-contactus-page bg-white p-4 md:p-8 md:mt-6 lg:mb-14 mb-8 border-radius-50 drop-shadow-lg">
        <p className='font-bold color-lightblack'>Registered Office:</p>
        <p className='color-lightblack mb-8'>NDRAVG Business Park, Plot No. A, PD Plot, Sarita Vihar, Delhi 110 044</p>

        <p className='font-bold color-lightblack'>Head Office:</p>
        <p className='color-lightblack mb-8'>CP Wholesale India Pvt. Ltd. 7th & 8th Floor, WeWork BlueOne Square, 246, Phase IV, Udyog Vihar, Gurugram - 122016</p>

        <p className='color-lightblack mb-8'><span className='font-bold'>CIN: </span>U51909DL2017FTC310809</p>

        <p className='font-bold color-lightblack'>Wholesale Drug Licence No.:</p>
        <p className='color-lightblack mb-8'>DL-SHB-119779/ DL-SHB-119780/ DL-LXN-141238</p>

        <p className='font-medium mb-8'>We are here to help you at all times. Please reach out to us with your queries and we will get back to you soon.</p>

        <a className='card-textbg inline-flex mr-5 p-4 rounded-xl items-center justify-between md:w-80 w-full' href='mailto:care@pushkarfood.in
'>
          <span className='font-medium color-lightblack flex items-center'>
            <svg
              className='mr-2'
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="17"
              fill="none"
              viewBox="0 0 23 17"
            >
              <path
                fill="#CA2F2E"
                d="M23 2.022c0-.438-.528-.543-.839-.234l-3.431 3.41a4.072 4.072 0 000 5.777l3.431 3.41c.311.309.84.204.84-.234V2.022zM20.979 0H2.022c-.437 0-.54.527-.23.835l8.28 8.238c.788.788 2.07.788 2.858 0L21.21.835c.31-.308.206-.835-.231-.835zM.839 1.788C.528 1.48 0 1.584 0 2.022v12.129c0 .438.528.543.839.234l3.432-3.41a4.072 4.072 0 000-5.777L.839 1.788z"
              ></path>
              <path
                fill="#CA2F2E"
                d="M15.86 10.023a1.4 1.4 0 00-1.977.003 3.372 3.372 0 01-4.764 0 1.4 1.4 0 00-1.978-.003l-5.348 5.314c-.31.308-.208.835.23.835h18.956c.437 0 .54-.527.23-.835l-5.35-5.314z"
              ></path>
            </svg>
            Mail Us
          </span>
          <span className='font-medium color-lightblack'>care@pushkarfood.in</span>
        </a>
        <a className='card-textbg inline-flex p-4 rounded-xl items-center justify-between md:w-80 w-full' href='tel:+91 7042223498'>
          <span className='font-medium color-lightblack flex items-center'>
          <svg
            className='mr-2'
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            fill="none"
            viewBox="0 0 21 21"
          >
            <path
              fill="#CA2F2E"
              d="M20.406 15.412l-2.93-2.93c-1.047-1.047-2.826-.629-3.245.732-.314.942-1.36 1.465-2.303 1.256-2.093-.523-4.919-3.245-5.442-5.443-.314-.942.314-1.988 1.256-2.302 1.36-.419 1.78-2.198.733-3.245L5.544.55c-.837-.733-2.093-.733-2.826 0L.729 2.537c-1.988 2.093.21 7.64 5.129 12.56 4.92 4.92 10.466 7.222 12.56 5.128l1.988-1.988c.733-.837.733-2.093 0-2.826z"
            ></path>
          </svg>
            Call Us At
          </span>
          <span className='font-medium color-lightblack'>+91 7042223498</span>
        </a>
      </div>
    </Container>
  )
}

export default ContactUs