import React, { useState } from "react";

const PaymentGateway = () => {
  // Set "Online" as the default option
  const [selectedOption, setSelectedOption] = useState("Online");

  // Handle selection of a payment option
  const handleOptionSelect = (option) => {
    if (option === "COD") {
      return; // Prevent selection if COD is disabled
    }
    setSelectedOption(option);
    console.log(`Selected Payment Option: ${option}`);
  };

  return (
    <div>
      {/* Payment Options */}
      <div className="flex flex-col space-y-4">
        {/* Online Payment Option */}
        <div
          className={`flex items-center p-4 border rounded-md cursor-pointer ${
            selectedOption === "Online" ? "border-[#3C881F] bg-[#E2EDDD]" : "border-[#EFEFF1]"
          }`}
          onClick={() => handleOptionSelect("Online")}
        >
          <input
            type="radio"
            name="payment-method"
            value="Online"
            checked={selectedOption === "Online"}
            onChange={() => handleOptionSelect("Online")}
            className="mr-2 cursor-pointer"
          />
          <span className="text-lg font-medium">Online</span>
        </div>

        {/* COD Option */}
        <div
          className={`flex items-center p-4 border rounded-md cursor-pointer ${
            selectedOption === "COD" ? "border-[#3C881F] bg-[#E2EDDD]" : "border-[#EFEFF1]"
          }`}
          onClick={() => handleOptionSelect("COD")}
        >
          <input
            type="radio"
            name="payment-method"
            value="COD"
            disabled
            checked={selectedOption === "COD"}
            onChange={() => handleOptionSelect("COD")}
            className="mr-2 cursor-pointer"
          />
          <span className="text-lg font-medium">Cash on Delivery</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentGateway;
