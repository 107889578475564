enum CONSTANTS {
  ACTIVE = 0,
  INACTIVE = 1,
  PENDING = 2,
  APPROVED = 3,
  BLOCKED = 4,
  DELETED = 5,
  ENABLED = 6,
  DISABLED = 7,
  COMPLETED = 8,
  CANCELLED = 9,
  EDIT = 10,
  DELETE = 11,
  BLOCK = 12,
  UNBLOCK = 13,
  ADD = 14,
  REMOVE = 15,
  REMOVE_ALL = 16,
  UPDATE = 17,
  DRAFT = 18,
  SIGN_UP = 19,
  LOGIN = 20,
  VERIFY_TEMP = 21,
  CHECK_EMAIL = 22,
  CHECK_PHONE = 23,
  USER_FORGOT = 24,
  ADMIN_FORGOT = 25,
  EMAIL_VERIFICATION = 26,
  EMAIL_PASS = 27,
  PHONE_PASS = 28,
  PHONE = 29,
  EMAIL = 30,
  GOOGLE = 31,
  MALE = 32,
  FEMALE = 33,
  OTHER = 34,
  FLAT = 35,
  PERCENT = 36,
  FREE = 37,
  CHECK_BOX = 38,
  TEXT_SWATCH = 39,
  DROP_DOWN = 40,
  COLOR_SWATCH = 41,
  OPTION_BOX = 42,
  MULTI_SELECT = 43,
  RANGE = 44,
  CIRCLE = 45,
  SQUARE = 46,
  RECTANGLE = 47,
  USER = 48,
  ADMIN = 49,
  NO_FILTER = 50,
  BASIC = 51,
  ADVANCED = 52,
  OUT_OF_STOCK = 53,
  SIMPLE = 54,
  CONFIGURABLE = 55,
  CATALOG_IMAGE = 56,
  CATALOG_VIDEO = 57,
  CATALOG_REVIEW = 58,
  BRAND_IMAGE = 59,
  CATEGORY_IMAGE = 60,
  BANNER_IMAGE = 61,
  PROFILE_IMAGE = 62,
  IMAGE = 63,
  VIDEO = 64,
  DOC = 65,
  JPEG = 66,
  PNG = 67,
  GIF = 68,
  MP4 = 69,
  PDF = 70,
  STEP_1 = 1,
  STEP_2 = 2,
  STEP_3 = 3,
  STEP_4 = 4,
  RESTURANT = 71,
  RETAIL_STORE = 72,
  PROCESSING= 73,
  SUCCESS= 74,
  FAILED= 75,
  PRICE= 76,
  CREATED_DATE = 77,
}
export enum SORTING {
  ASCENDING = 1,
  DESCENDING = -1,
}

export enum COLLECTIONS {
  ADMIN = "admins",
  ADMIN_SESSION = "adminSessions",
  FILTER_TYPE = "filterType",
  FILTER_ATTRIBUTE = "filterAttribute",
  FILTER_OPTIONS = "filterOptions",
  FILTER_GROUP = "filterGroup",
  CATALOG = "catalog",
  CATEGORY = "category",
  USER = "users",
  USER_SESSION = "userSessions",
  BRAND = "brands",
  TAG = "tags",
  OTP = "otps",
  TEMP_DATA = "tempData",
  WISHLIST = "wishlist",
  DELETED = "deleted",
  TAX = "tax",
  HSN = "hsn",
  INVENTORY = "inventory",
  USER_CART = "userCart",
  CART_HISTORY = "cartHistory",
  ORDERS = "order",
  USER_ADDRESS = "userAddress",
  FILE_UPLOAD_HISTORY = "fileUploadHistory",
}

export const ENUM = {
  OFFER_TYPE: {
    FLAT: CONSTANTS.FLAT,
    PERCENT: CONSTANTS.PERCENT,
  },

  OFFER_STATUS: {
    ACTIVE: CONSTANTS.ACTIVE,
    INACTIVE: CONSTANTS.INACTIVE,
    PROCESSING: CONSTANTS.PROCESSING,
    SUCCESS: CONSTANTS.SUCCESS,
    FAILED: CONSTANTS.FAILED
  },

  ADMIN_PROFILE_STATUS: {
    ACTIVE: CONSTANTS.ACTIVE,
    BLOCKED: CONSTANTS.BLOCKED,
    DELETED: CONSTANTS.DELETED,
  },

  GENDER: {
    MALE: CONSTANTS.MALE,
    FEMALE: CONSTANTS.FEMALE,
    OTHER: CONSTANTS.OTHER,
  },

  PLANS: {
    FREE: CONSTANTS.FREE,
  },

  FILTER_TYPES: {
    CHECK_BOX: CONSTANTS.CHECK_BOX,
    TEXT_SWATCH: CONSTANTS.TEXT_SWATCH,
    DROP_DOWN: CONSTANTS.DROP_DOWN,
    COLOR_SWATCH: CONSTANTS.COLOR_SWATCH,
    OPTION_BOX: CONSTANTS.OPTION_BOX,
    MULTI_SELECT: CONSTANTS.MULTI_SELECT,
    RANGE: CONSTANTS.RANGE,
  },

  USER_PROFILE_STATUS: {
    ACTIVE: CONSTANTS.ACTIVE,
    BLOCKED: CONSTANTS.BLOCKED,
  },

  USER_GST_STATUS: {
    PENDING: CONSTANTS.PENDING,
    APPROVED: CONSTANTS.APPROVED,
  },

  LOGIN_TYPE: {
    PHONE: CONSTANTS.PHONE,
    EMAIL: CONSTANTS.EMAIL,
  },

  ADMIN_ACTION_TYPE: {
    EDIT: CONSTANTS.EDIT,
    DELETE: CONSTANTS.DELETE,
    BLOCK: CONSTANTS.BLOCK,
    UNBLOCK: CONSTANTS.UNBLOCK,
  },

  SIGNUP_TYPE: {
    GOOGLE_SIGNUP: CONSTANTS.GOOGLE,
    EMAIL_SIGNUP: CONSTANTS.EMAIL,
    PHONE_SIGNUP: CONSTANTS.PHONE,
    VERIFY_TEMP: CONSTANTS.VERIFY_TEMP,
  },

  AUTH_REQUEST_TYPE: {
    CHECK_EMAIL: CONSTANTS.CHECK_EMAIL,
    CHECK_PHONE: CONSTANTS.CHECK_PHONE,
    SIGN_UP: CONSTANTS.SIGN_UP,
  },

  CATEGORY_STATUS: {
    ENABLED: CONSTANTS.ENABLED,
    DISABLED: CONSTANTS.DISABLED,
    DELETED: CONSTANTS.DELETED,
  },

  TAG_SHAPE: {
    CIRCLE: CONSTANTS.CIRCLE,
    SQUARE: CONSTANTS.SQUARE,
    RECTANGLE: CONSTANTS.RECTANGLE,
  },

  OTP_TYPE: {
    SIGNUP: CONSTANTS.SIGN_UP,
    USER_FORGOT: CONSTANTS.USER_FORGOT,
    ADMIN_FORGOT: CONSTANTS.ADMIN_FORGOT,
    EMAIL_VERIFICATION: CONSTANTS.EMAIL_VERIFICATION,
  },

  TEMP_ACTION_TYPE: {
    SIGNUP: CONSTANTS.SIGN_UP,
  },

  FORGET_REQ_TYPE: {
    CHECK_EMAIL: CONSTANTS.CHECK_EMAIL,
    CHECK_PHONE: CONSTANTS.CHECK_PHONE,
    EMAIL_PASS: CONSTANTS.EMAIL_PASS,
    PHONE_PASS: CONSTANTS.PHONE_PASS,
  },

  ADMIN_FORGET_REQ_TYPE: {
    CHECK_EMAIL: CONSTANTS.CHECK_EMAIL,
    EMAIL_PASS: CONSTANTS.EMAIL_PASS,
  },

  WISHLIST_REQ_TYPE: {
    ADD: CONSTANTS.ADD,
    REMOVE: CONSTANTS.REMOVE,
    REMOVE_ALL: CONSTANTS.REMOVE_ALL,
  },

  PROFILE_UPDATE_TYPE: {
    LOGIN: CONSTANTS.LOGIN,
    SIGNUP: CONSTANTS.SIGN_UP,
    UPDATE: CONSTANTS.UPDATE,
  },

  DOC_DELETED_BY: {
    USER: CONSTANTS.USER,
    ADMIN: CONSTANTS.ADMIN,
  },

  ROLE: {
    ADMIN: CONSTANTS.ADMIN,
    USER: CONSTANTS.USER,
  },

  PAYMENT_STATUS: {
    PENDING: CONSTANTS.PENDING,
    COMPLETED: CONSTANTS.COMPLETED,
    CANCELLED: CONSTANTS.CANCELLED,
  },

  INVENTORY_TYPE: {
    NO_FILTER: CONSTANTS.NO_FILTER,
    BASIC: CONSTANTS.BASIC,
    ADVANCED: CONSTANTS.ADVANCED,
    OUT_OF_STOCK: CONSTANTS.OUT_OF_STOCK,
  },

  COMMON_STATUS: {
    INACTIVE: CONSTANTS.INACTIVE,
    ACTIVE: CONSTANTS.ACTIVE,
    DELETED: CONSTANTS.DELETED,
  },

  CATALOG_STATUS: {
    DRAFT: CONSTANTS.DRAFT,
    PENDING: CONSTANTS.PENDING,
    ACTIVE: CONSTANTS.ACTIVE,
    DELETED: CONSTANTS.DELETED,
  },

  CATALOG_TYPE: {
    SIMPLE: CONSTANTS.SIMPLE,
    BASIC: CONSTANTS.BASIC,
    CONFIGURABLE: CONSTANTS.CONFIGURABLE,
  },

  FILE_CATEGORY_TYPE: {
    CATALOG_IMAGE: CONSTANTS.CATALOG_IMAGE,
    CATALOG_VIDEO: CONSTANTS.CATALOG_VIDEO,
    BRAND_IMAGE: CONSTANTS.BRAND_IMAGE,
    CATEGORY_IMAGE: CONSTANTS.CATEGORY_IMAGE,
    USER_PROFILE_IMAGE: CONSTANTS.PROFILE_IMAGE,
  },

  FILE_TYPE: {
    IMAGE: CONSTANTS.IMAGE,
    VIDEO: CONSTANTS.VIDEO,
    DOC: CONSTANTS.DOC,
    OTHER: CONSTANTS.OTHER,
  },

    FILE_EXTENSION_TYPE: {
        JPEG: CONSTANTS.JPEG,
        PNG: CONSTANTS.PNG,
        GIF: CONSTANTS.GIF,
        MP4: CONSTANTS.MP4,
        PDF: CONSTANTS.PDF,
        OTHER: CONSTANTS.OTHER,
    },
    TIMER: {
        MINUTES: 0,
        SECONDS: 45
    },
    SIGNUP_STEP: {
        STEP_1: CONSTANTS.STEP_1,
        STEP_2: CONSTANTS.STEP_2,
        STEP_3: CONSTANTS.STEP_3,
        STEP_4: CONSTANTS.STEP_4
    },
    BUSSINESS_TYPE: {
        RESTURANT: CONSTANTS.RESTURANT,
        RETAIL_STORE: CONSTANTS.RETAIL_STORE
    },
    SORT_BY: {
        PRICE: CONSTANTS.PRICE,
        CREATED_DATE: CONSTANTS.CREATED_DATE,
    },
    SECTIONS: {
      SOCIAL_SETTINGS: 71,
      CONTACT_SETTINGS: 72,
      HERO_BANNER_SETTINGS: 73,
      GROWTH_SETTINGS: 74,
      BRANDS_SETTINGS: 75,
      USER_REVIEW_SETTINGS: 76,
      BESTSELLERS_SETTINGS: 77,
    }
};

export enum SQUARE_IMAGE_SIZES {
  SIZE_64x64 = "64x64",
  SIZE_128x128 = "128x128",
  SIZE_256x256 = "256x256",
  SIZE_512x512 = "512x512",
  SIZE_640x640 = "640x640",
  SIZE_768x768 = "768x768",
  SIZE_1024x1024 = "1024x1024",
}

export enum STORAGE_FOLDERS {
  DOCS = "docs",
  CATALOG = "catalog",
  CATALOG_IMAGE = "catalog-image",
  CATALOG_VIDEO = "catalog-video",
  BRAND = "brand",
  LOGO = "logo",
  CATEGORY = "category",
  BANNER = "banner",
  CATALOG_REVIEW = "catalog-review",
  USER_PROFILE = "user-profile",
}
